.finalScreenHeading {
  width: 85%;
  margin: auto auto;
  padding-top: 2.06em;
  padding-bottom: 1.06em;
}
.finalScreenHeading p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  color: #1d2334;
}
.finalScreenDetails {
  width: 85%;
  margin: auto auto;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}
.finalScreenDetailsName {
  padding-top: 2.5em;
  padding-bottom: 2.6em;
  display: flex;
  position: relative;
  border-bottom: 1px solid #f2efea;
}
.finalScreenDetailsNameChild {
  display: flex;
  padding-left: 2.1em;
  gap: 1.1em;
  height: 3em;
  align-items: center;
}
.finalScreenDetailsNameChild :nth-child(1) {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 150%;
  /* color: #1d2334; */
  margin-top: auto;
  margin-bottom: auto;
}
.finalScreenDetailsNameChild :nth-child(2) {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* color: #f2efea; */
  /* background: #9c27b0; */
  
  border-radius: 6px;
  /* padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.1em;
  padding-bottom: 0.1em; */
  margin-top: auto;
  margin-bottom: auto;
}

.finalScreenDetailsNameButton {
  position: absolute;
  right: 2em;
  width: 11em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #52608e;
  border-radius: 6px;
}
.finalScreenDetailsNameButton p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 22px;
  color: #52608e;
}
.finalScreenDetailsDescription {
  width: 85%;
}
.finalScreenDetailsDescriptionChild1 {
  padding-top: 1.93em;
  padding-left: 2.1em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}
.finalScreenDetailsDescriptionChild2 {
  padding-top: 1.75em;
  padding-left: 2.1em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}

.personalDetails {
  display: flex;
  margin-top: 1.43em;
}
.personalDetailsParent {
  margin-top: 2.06em;
}
.field {
  width: 25%;
  margin-left: 2.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.field p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.12em;
  line-height: 150%;
  color: #1d2334;
}
.value {
  width: 75%;
  margin-left: 5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.value p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}
.finalScreenDetailsCompletedButton {
  padding-top: 1.8em;
  padding-bottom: 1.8em;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #319b4b;
}
.finalScreenDetailsCompletedButton button {
  width: 20em;
  height: 3em;
  background: #e68d36;
  border-radius: 12px;
}
.finalScreenDetailsUploadReport {
  padding-top: 1.8em;
  display: flex;
  align-items: baseline;
}
.finalScreenDetailsUploadReportFiles{
  margin-left: 10em;
}
.finalScreenDetailsUploadReportChild1 {
  cursor: pointer;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
  width: 21.5%;
  margin-left: 2.1em;
}
.finalScreenDetailsUploadReportChild2 {
  max-width: 90%;
  display: flex;
  justify-content: space-around;
  margin-left: 6vw;
}
.finalScreenDetailsUploadReportChild2 button {
  color: white;
  cursor: pointer;
  width: 20em;
  height: 3em;
  background: #e68d36;
  border-radius: 12px;
  margin-right: -3vw;
  border: none;
  outline: none !important;
}
.finalScreenDetailsUploadReportChild2 input {
  width: 21em;
  height: 3em;
  background: #ffffff;
  border: 1px solid #1d2334;
  margin-right: 3vw;
  border-radius: 12px;
}

.finalScreenDetailsFinalSubmit {
  padding-top: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 6em;
}
.finalScreenDetailsFinalSubmit button {
  cursor: pointer;
  background: #319b4b;
  outline: none !important;
  border: none;
  color: white;
  border-radius: 12px;
  width: 20em;
  height: 3em;
}

.finalScreenDetailsUploadReportChildText {
  display: flex;
  margin-bottom: 1em;
  margin-left: 3em;
}

.finalScreenDetailsUploadReportChildText1 {
  margin-right: 36.8vw;
}
.finalScreenDetailsUploadReportChildIcon{
  margin-left: 1em;
}
.UNpaidpersonalDetails {
  display: flex;
  margin-top: 1.43em;
  margin-bottom: 2vw;
}
@media screen and (max-width: 900px) {
  .finalScreenDetailsUploadReportChild2 button {
    width: 12em;
  }
  .finalScreenDetailsUploadReportChild2 input {
    width: 15em;
  }
}