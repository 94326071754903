.h3 {
  margin-left: 6.87rem;
  margin-top: 2.06em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
}

.service-box {
  margin-left: 6.87em;
  margin-top: 2em;
  margin-right: 8.75em;
  background-color: white;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}

.service-box-head {
  margin-left: 2.125em;
  margin-top: 2.5em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 2.625em;
  margin-right: 4.75em;
}

.service-name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}

.service-editBtn {
  border: none;
  outline: none !important;

  background-color: white;
  color: blue;
  cursor: pointer;
}

.service-edit {
  display: flex;
  color: blue;
}

.desc-head {
  margin-top: 1.93em;
  margin-left: 2.125em;
  margin-right: 4.75em;
  display: flex;
  justify-content: space-between;
}

.service-description {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
}

.service-box-body {
  margin-left: 2.125em;
  margin-top: 1.68em;
  margin-right: 1.81em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.service-grid-head {
  display: flex;
  justify-content: space-between;
  margin-top: 5.5em;
  margin-left: 2.125em;
  margin-right: 2em;
}

.atrribute {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

.addAtributeBtn {
  color: white;
  background: #319b4b;
  border-radius: 6px;
  border: none;
  outline: none !important;

  padding-top: 0.68em;
  padding-bottom: 0.68em;
  padding-left:  0.65em;
  padding-right:  0.65em;
  cursor: pointer;
}
