.App {
  background-color: #f9f9f9;
}

.mobile {
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 50vh;
  height: 100vh;
}

.logoAppMain {
  width: 17.5%;
  margin-top: 3.054375em;
  margin-left: 40vw;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

button {
  cursor: pointer;
}


::placeholder{
  color: #1D2334;
  /* font-style: italic; */
}

.swal2-container{
  z-index: 9999!important;
}