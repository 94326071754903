.mainHeading {
  width: 80%;
  padding-top: 2.06rem;
  margin: auto auto;
  display: flex;
  align-items: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 1.5em;
  color: #1d2334;
}
.boxTop {
  width: 80%;
  height: 6.68em;
  margin: auto auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}

.boxTop .boxTopName {
  padding-left: 1.8em;
  color: #1d2334;
}

.boxBottom {
  width: 80%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  padding-left: 2.12em;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
  padding-bottom: 5em;
}

.boxBottomName {
  padding-top: 1.18em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.5em;
  color: #1d2334;
  margin-bottom: 1em;
}
.boxBottomDesc {
  margin-top: 1.25em;
  width: 80%;
  color: #1d2334;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
}

.templateCard {
  width: 60%;
  margin-top: 2.6em;
  display: flex;
  background: #ffffff;

  border: 1px solid #1d2334;
  border-radius: 0.75em;
}
.edit {
  color: #1d2334;
}
.serviceName {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 2.6em;
  color: #1d2334;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.12em;
  line-height: 150%;
}
.serviceDetails {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1d2334;
}
.attribute {
  width: 60%;
  margin-top: 1.62em;
  display: flex;
  justify-content: space-between;
}
.boxBottomRelation{
  margin-top: 1.25em;
  width: 80%;
  color: #1d2334;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
}
/* .card-Content{
display: flex;
flex-direction: column;
} */
.pharmacyServiceProgramDetail{
  width: 60%;

  border: 1px solid #1D2334;
  border-radius: 0.75em;
  display: flex;
flex-wrap: wrap;
margin-top: 3em;
}
.pharmacyService1Program{
  width: 30%;
  font-size: 1.12em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1D2334;
  padding-top: 1em;
  padding-bottom: 1em;
}