.inputFieldsContainerBanner {
    display: flex;
    flex-direction: column;
    margin-left: 6.875em;
}

.uploadButtonBanner {
    border-radius: 12px;
    background: #E68D36;
    color: white;
    padding: 0.75em 5.5vw;
    border: none;
    margin-left: 2vw;
}

.modalButtonBanner {
    border-radius: 12px;
    background: #52608E;
    color: white;
    padding: 1.375em 2vw;
    border: none;
    margin-top: 3em;
}