* {
  margin: 0;
  padding: 0;
}
.mainTermsAndConditions {
  display: flex;
  flex-direction: column;
  /* height: 800vh; */
  /* justify-content: space-evenly; */
  background-color: #ffffff;
}
.termsAndConditionsTop {
  /* height: 50vh; */
  display: flex;
  justify-content: center;
}
.FooterBackgroundImgArc {
  position: absolute;
  /* top: 4em; */
}
.FooterBackgroundImage {
  position: absolute;
  /* top: 4em; */
}
.FooterImage {
  position: relative;
  top: 1.5em;
}
.termsAndConditionsBottom {
  display: flex;
  justify-content: center;
  /* height: 750vh; */
  background-color: #ffffff;
}

.contentTermsAndConditions {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  padding-top: 2em;
  /* position: absolute; */
  z-index: 1;
}
.contentTermsAndConditionsHeading {
  padding-left: 3%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 2.5em;
  line-height: 1.5em;
  color: #2d2424;
}
.contentTermsAndConditionsBottomHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.5em;
  padding-left: 3%;
  color: #292828;
}
.contentTermsAndConditions p {
  padding-left: 3%;
  padding-right: 3%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  color: #292828;
}
