.footer {
  display: flex;
  flex-direction: column;
  background: #44576a;
  /* height: 40vh; */
}
.upperFooter {
  margin-left: 5.5em;
  margin-right: 5.5em;
  display: flex;
  border-bottom: #f3f0f0;
  /* justify-content: space-evenly; */
}
.downFooter {
  width: 90%;
  height: 20%;
  align-self: center;
  border-top: 1px solid #8e8e8e;
  display: flex;
  gap: 1%;
}
.downFooter .copyrightText {
  height: 100%;
  display: flex;
  padding-top: 1.4%;
  align-items: center;
  color: #eaeaea;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}
.downFooter .copyrightSign {
  display: flex;
  align-items: center;
}

.upperChild1 {
  margin-top: 2.81em;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 2.5em;
}

.upperChild2Footer {
  /* display: flex; */
  margin-top: 2.81em;
  width: 25%;
  margin-bottom: 2.5em;
}

.upperChild3Footer {
  margin-top: 2.81em;
  margin-bottom: 2.5em;
  width: 25%;
}
.upperChild4 {
  margin-top: 2.31em;
  margin-bottom: 2.5em;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.upperChild1 img {
  width: 0.83em;
  height: 0.66em;
}
.upperChild2Footer p {
  /* text-align: left; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-size: 1.3em;
  line-height: 1.5;
  padding-top: 2.81%;
  color: #ffffff;
}
.upperChild2Footer li {
  /* text-align: left; */
  list-style: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 1.5em;
  color: #ffffff;
  /* padding-left: 1.9em; */
  padding-top: 1em;
}
.upperChild3Footer p {
  text-align: left;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-size: 1.3em;
  line-height: 1.5em;
  padding-top: 2.81%;
  /* padding-left: 1.8em; */
  color: #ffffff;
}
.upperChild3Footer li {
  /* text-align: left; */
  list-style: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 1.5em;
  color: #ffffff;
  /* padding-left: 1.9em; */

  padding-top: 1em;
}
.upperChild4 div {
  text-align: left;
}
.upperChild4Heading {
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-size: 1.3em;
  margin-top: -.9em;
}
.upperChild4Bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.upperChild4Description {
  width: 100%;
}

.upperChild4Description span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1.5em;
  color: #ffffff;
}
.emailInput {
  margin-top: 1em;
  height: 2.62em;
  display: flex;
  width: 80%;
}
.emailInput input {
  height: 100%;
  width: 100%;
  background: #f3f0f0;
  border-radius: 0.37em;
  padding-left: 1.3em;
}
.signup {
  margin-top: 2.5vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75vw;
  line-height: 1.5em;
  height: 3.62vw;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #38466c;
  border-radius: 0.37em;
  border: 1px solid #38466c;
  color: #ffffff;
}
.signup img {
  padding-right: 3%;
}
.address {
  margin-top: 1em;
  width: 80%;
  font-size: 0.9em;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  text-align: left;
  line-height: 1.5em;
  color: #ffffff;
}
.logoImage {
  text-align: left;
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.logoImage img {
  width: 7.8em;
  height: 2.3em;
}
.emailContact {
  display: flex;
  text-align: center;
  width: 50%;
  color: #ffffff;
  gap: 4%;
}
.phoneContact {
  display: flex;
  text-align: center;
  margin-top: 0.62em;
  width: 50%;
  color: #ffffff;
  gap: 4%;
}
.emailContact img {
  margin-top: 0.3em;
  width: 0.83em;
  height: 0.7em;
}
.phoneContact img {
  margin-top: 0.3em;
  width: 0.53em;
  height: 0.7em;
}

.emailContact p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 1.5em;
  width: 0.83em;
  height: 0.7em;
}
.phoneContact {
  width: 102%;
}
.phoneContact p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 1.5em;
}
.socialmediaContact {
  display: flex;
  width: 45%;
  justify-content: space-between;
  gap: 4%;
  margin-bottom: 0.5em;
}
.socialmediaContact img {
  width: 1.09em;
  height: 1.06em;
}
@media screen and (max-width: 900px) {
  .emailContact {
    width: 100%;
  }
  .emailContact p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 0.7em;
    line-height: 1.5em;
    width: 0.83em;
    height: 0.7em;
  }
  .phoneContact {
    width: 100%;
  }
  .phoneContact p {
    display: flex;
    align-items: flex-start;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 0.87em;
    line-height: 1.5em;
  }
  .upperChild2Footer li {
    padding-top: 3em;
    font-size: 0.75em;
    padding-left: 2.3em;

  }
  
  .upperChild4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .upperChild4Description p {
    font-size: 1em;
  }
  .upperChild4Heading p {
    padding-top: 4%;
  }
  .signup {
    margin-bottom: 1em;
  }
  .upperChild3Footer li {
    padding-top: 3em;
    font-size: 0.75em;
  }
}
