.BMI_Donut_OuterContainer {
  display: flex;
  flex-direction: column;
}
.BMI_Donut_OuterContainer {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  padding-bottom: 2vw;
  justify-content: space-around;
}

.BMI_ObeseHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  color: #1d2334;
  margin-top: 22px;
  display: flex;
  width: 9vw;
  /* height: 10vh; */
  /* padding-bottom: 2vw; */
}
.BMI_ObeseSubHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  margin-top: 6px;
  color: #f07162;
}
.Nutri_Heading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  color: #1d2334;
  margin-top: 2px;
  margin-left: 2vw;
  display: flex;
}
.Nutri_SubHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  margin-left: 2vw;
  font-size: 24px;
  line-height: 150%;
  margin-top: 6px;
  padding-bottom: 2vw;
  color: #f07162;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left {
  inset: auto 0px 1px;
  position: absolute;
  max-height: 120px;
  display: flex;
  justify-content: space-evenly;
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}
/* .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center{
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
} */

.chartsinputfields {
  padding-left: 1.625em;
  width: 23.5vw;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-top: 0.6875em;
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
}

.DashboardInputFields {
  padding-left: 2vw;
  /* margin-left: 6vw; */
  margin-right: 80px;
  margin-bottom: 2vw;
  /* margin-left: 80px; */
  width: 100%;
  /* padding-right: 1vw; */
  height: 4vw;
  /* border: 1px solid #52608e; */
  border-radius: 0.6vw;
  background-color: white;
  color: #1d2334;
  border: none;
  outline: none !important;
  margin-top: 0.6875em;
  font-family: "Nunito";
  /* font-style: italic; */
  font-weight: 400;
  margin-top: 5.2vw;
  font-size: 1em;
  line-height: 150%;
}

.infoMentalHealth {
  /* position: relative; */
  display: inline-block;
  margin-left: 12vw;
  margin-top: 0vw;
  position: absolute;
  /* overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; */
  /* width: 190px; */
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
}

/* Tooltip text */
.infoMentalHealth .myServicesDescriptionText {
  visibility: hidden;
  width: 30vw;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 9999;
}

/* Show the tooltip text when you mouse over the tooltip container */
.infoMentalHealth:hover .myServicesDescriptionText {
  visibility: visible;
}

/* .infoMentalHealth{
      margin-left: 23vw;
      margin-top: -2vw;
      position: absolute;
  } */
