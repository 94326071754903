.editCategoryHeader {
    /* margin: 1vw 0vw 1vw 2vw; */
    border-bottom: 1px solid #ede8e8;
    padding-top: 1em;
    padding-left: 2em;
    padding-bottom: 1em;
    border-radius: 12px;
  background-color: white;
    font-size: 32px;
    line-height: 48px;
    border: 1px solid #ede8e8;
  }
  
  .editProductMainBody {
    margin-left: 4.2em;
    margin-right: 4.2em;
  
    border-radius: 12px;
    margin-top: 5em;
    padding-bottom: 5em;
    /* border: 1px solid #EDE8E8; */
    display: flex;
    flex-direction: column;
    /* margin-bottom: 2em; */
  }
  .editCategoryBody {
    border: 1px solid #ede8e8;
    border-radius: 12px;
    background-color: white;
  }
  
  .editCategoryName {
    /* padding-left: 4em; */
    padding-top: 4em;
  
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-left: 0.2em;
  }
  
  .editCategoryField {
    margin-top: 0.5em;
    outline: none;
    /* padding-right: 70em; */
    width: 73vw;
    padding-left: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 12px;
    font-style: italic;
    /* outline: none !important; */
    /* border: none  !important; */
  }
  

  .editCategoryFieldSelect {
    width: 1350px; /* Set your desired width */
    outline: none;
    padding: 0.5em;
    border-radius: 12px;
    font-style: italic;
    margin-right: 10px; /* Add some spacing between the two fields */
  }
  /* .editCategoryFieldSelect {
   
    outline: none;
    padding-right: 77.5em;
    padding-left: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 12px;
    font-style: italic;
  } */
  
  .editCategoryDescription {
    padding-top: 2em;
  
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-left: 0.2em;
  }
  
  .editCategoryFormikBody {
    margin-left: 4em;
  }
  
  .editSwitchBody {
    display: flex;
    padding-top: 2em;
    justify-content: space-between;
    margin-right: 70em;
  }
  
  .editCategorySwitchName {
    padding-top: 0.3em;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-left: 0.2em;
  }
  
  .editCategorySwitchName1 {
    padding-top: 0.4em;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-left: 0.2em;
  }
  
  .editCategorySwitchButton {
    margin-left: 10em;
  }
  
  .editCategorySwitchButton1 {
    margin-left: 10em;
    margin-bottom: -0.6em;
  }
  .editSubmitButton {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 7em;
    padding-left: 7em;
    display: flex;
    margin-top: 3em;
    background-color: #52608e;
    border-radius: 6px;
    color: white;
    outline: none !important;
    border: none  !important;
    margin-bottom: 10em;
  
  }

  .clearButtonProduct{
    width:7vw;
     padding-top: 0.5vw;
     padding-bottom: 0.5vw;
     background-color: #52608e;
     color: white;
     border-radius: 12px;
   
   
   }
   
   .editButtonProduct{
     width:7vw;
   
     padding-top: 0.5vw;
     padding-bottom: 0.5vw;
     margin-right: 2vw;
     background-color: #52608e;
     color: white;
     border-radius: 12px;
   
   
   }
  