.NewUserLoginPart1 {
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/Login/Background.png");
}

.NewUserLoginMainWrapper {
  height: 100vh;
  background-image: url("../../../assets/Login/BackgroundImage.png");
  background-size: 100% 120%;
  background-repeat: no-repeat;
}

.NewUserLoginPart1Image {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
}

.NewUserLoginPart1Image_1 {
  width: 8em;
  height: 8em;
  padding-top: 2.1em;
  padding-right: 2.06em;
}

.NewUserLoginPart1Image_2 {
  width: 4em;
  height: 4em;
  margin-bottom: 5.81em;
  margin-right: 3.2em;
}

.NewUserLoginPart1Text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}

.NewUserLoginPart1TextWrapper {
  width: 50%;
  padding-top: 3.6em;
  padding-left: 4.41em;
  padding-bottom: 3.8em;
}

.NewUserLoginPart2 {
  margin-bottom: 2em;
  height: 20em;
}

.NewUserLoginPart2 img {
  width: 60%;
  height: 30em;
  margin-top: 4em;
  margin-left: 4.6em;
}

.AreYouHR {
  background: #f6d6b6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AreYouHR p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 150%;
  color: #1d2334;
  padding-left: 5.6em;
  padding-top: 1.8em;
  padding-bottom: 1.8em;
  margin-top: auto;
  margin-bottom: auto;
}

.AreYouHR button {
  background: var(--globalColorButton);
  border-radius: 12px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 19px;
  color: #ffffff;
  padding: 0.7em 2.1em 0.7em 2.1em;
  border: hidden;
  margin-right: 8.3em;
}

.SelectRoleDropdown {
  margin-top: 1em;
  margin-bottom: 3em;
  padding: 1em 2em 1em 2em;
}

.SelectRoleMainDiv {
  margin-left: 2.1vw;
}

.loginScreenMain {
  width: 35%;
  position: absolute;
  right: 5.3em;
  top: 5.6em;
  background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding-bottom: 2em;
}

.loginScreenMainHeader {
  margin-top: 2.7em;
}

.errorMessage {
  color: red;
}

.loginScreenMainHeader p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #1d2334;
  padding-left: 2.1vw;
}

.loginScreenMainGoogleArea {
  /* position: relative; */
  display: flex;
  justify-content: center;
  margin-top: 2em;
  background-color: aqua;
}

.newUserLoginForm {
  margin-left: 2vw;
}

.newgoogle {
  /* position: absolute; */
  width: 80%;
  height: 5vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 19px;
  color: #44576a;
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  background: #ffffff;
  border: white;
}

.signInDivNew {
  width: 100%;
  background-color: #ffffff;
}

.formStarts1New {
  display: flex;
  flex-direction: column;
  margin-top: 2.625em;
}

.boxNew {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.boxNew2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 2em;
}

.labelpara {
  width: 80%;
  margin-right: auto;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  color: #1d2334;
  align-self: flex-start;
  margin-bottom: 1em;
}

.login_textbox_new {
  /* height: 100%; */
  height: 3.4vw;
  background: #f3f0f0;
  border-radius: 0.6vw;
  width: 28vw;
  border: hidden;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 21px;
  color: #808080;
  padding-left: 1.125em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.UserLoginButtonNew {
  display: flex;
}

.submitbuttonNew {
  width: 28vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6vw;
  height: 3.4vw;
  color: white;
  cursor: pointer;
  gap: 2%;
  background: #ad343e;
  border: 1px solid #a93c3c;
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
}

.forgotpasswordNew {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 15px;
  color: #4285f4;
  padding-top: 0.5em;
  cursor: pointer;
}

.loginScreenMainHeaderForgot {
  margin-top: 2em;
  padding-left: 1em;
}

.loginScreenMainHeaderForgot :nth-child(1) {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 28px;
  color: #1d2334;
}

.loginScreenMainHeaderForgot :nth-child(2) {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2em;
  line-height: 43px;
  color: #1d2334;
}

.retrieve {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 28px;
  color: #407bff;
}

.formStarts1Newforget {
  margin-top: 7em;
}

.loginScreenMainHeaderReset {
  margin-top: 2em;
  padding-left: 2em;
}

.loginScreenMainHeaderReset :nth-child(1) {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 28px;
  color: #1d2334;
}

.loginScreenMainHeaderReset :nth-child(2) {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 2.5em;
  line-height: 43px;
  color: #1d2334;
}

.loginScreenMainReset {
  min-height: 80vh;
}

.googleNew {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.S9gUrf-YoZ4jf {
  margin: 0 auto;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nsm7Bb-HzV7m-LgbsSe {}

.orRegister {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 481px) and (max-width: 700px) {
  .NewUserLoginPart1Text {
    font-size: 0.8em;
  }
}

@media screen and (min-width: 1440px) {
  .NewUserLoginMainWrapper {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}