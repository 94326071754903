* {
    margin: 0px;
    padding: 0px;
  }
  .template1 {
    background: #e7eaed;
    display: flex;
    flex-direction: column;
  }
  .templateTop1 {
    width: 80%;
    margin-top: 2.06em;
    margin-left: auto;
    margin-right: auto;
  }
  .templateTop1 span {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 2em;
    color: #1d2334;
  }
  
  .templateBottom1 {
    width: 80%;
    margin-top: 1.06em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  .templateBottomLeft1 {
    /* width: 48.56em; */
    width: 70%;
    background: #ffffff;
    height: 48em;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0.75em;
  }
  .templateBottomRight1 {
    /* width: 30.5em; */
    width: 30%;
    background: linear-gradient(12.37deg, #52608e 0%, #18203a 100%);
    border: 1px solid #ede8e8;
    border-radius: 0.75em;
  }
  .loadTemplate1 {
    width: 90%;
  
    /* margin-left: 2.75em;
      margin-right: 2.75em; */
    margin: auto auto;
    margin-top: 3em;
    background: #f2efea;
    border-radius: 0.75em;
  }
  .addServicesHeading1 {
    width: 90%;
    height: 3em;
    /* background: #319b4b; */
    background: transparent;
    border-radius: 12px;
    margin-top: 3.75em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 4.81em;
      padding-top: 0.6em;
      padding-bottom: 0.68em; */
  }
  .addServicesHeading1 span {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5em;
    color: #ffffff;
  }
  .allServices1 {
    height: 20em;
    overflow-y: auto;
  }
  .servicesList1 {
    margin-top: 1em;
    display: flex;
    align-items: center;
  }
  .servicesList1 button {
    margin-left: 4.56em;
    width: 1.1em;
    height: 1.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none !important;
  
    color: white;
    outline: none;
  
  }
  .servicesList1 span {
    margin-left: 1.3em;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    color: #ffffff;
  }
  .addTemplateSubmit1 {
    width: 90%;
    margin-top: 5em;
    text-align: center;
    background: #e68d36;
    border-radius: 0.75em;
    height: 3em;
    margin-top: 3.87em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .addTemplateSubmit1 span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.1em;
    color: #f2efea;
  }
  .addTemplateSubmit1 span:hover {
    cursor: pointer;
  }
  
  .main-Newcontainer {
    display: flex;
    flex-direction: column;
  }
  .inner-mainNewContainer {
    width: 179%;
    /* background-color: #319B4B; */
    display: flex;
    justify-content: space-between;
    margin-top: 3.25em;
    margin-left: 3.81em;
    margin-right: auto;
    flex-direction: column;
  }
  
  .lower {
    width: 100%;
  }
  .corporateNameBox input {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 1.62em;
    width: 79%;
    border-radius: 0.75em;
    margin-top: 2.75em;
    border: 1px solid #1d2334;
    border-radius: 0.75em;
    margin-left: 3.81em;
  }
  
  .red {
    background-color: brown;
    margin-top: 900px;
  }
  .mandatory-field-date {
    color: red;
    /* margin-left: 14%; */
    padding: 3px;
  }
  
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: grey !important;
    border-color: grey !important;
  }
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: grey !important;
  }
  .newinputField {
    padding-left: 1.625em;
    width: 30em;
    height: 2.5em;
  
    background-color: white;
    color: #1d2334;
    margin-bottom: 2.6875em;
    font-family: "Nunito";
    font-style: italic;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
  
    margin-left: 2.51em;
  }
  .mandatory-field-pro {
    color: red;
    margin-left: 7%;
    padding: 3px;
  }
  .testt {
    display: flex;
    flex-direction: row;
    /* margin-left: 52px; */
    /* width: 320px; */
    /* justify-content: space-evenly; */
  }
  .testtt {
    margin-left: 25%;
  }
  .try {
    display: flex;
    flex-direction: row;
  }
  .helo {
    width: 44%;
    /* background-color: #52608E; */
  }
  @media screen and (max-width: 1300px) {
    /* .addServicesHeading{
          padding-left: 1em;
          font-size: .9em;
          text-align: center;
      } */
    .templateTop1 {
      margin-left: auto;
      margin-right: auto;
    }
    .templateBottom1 {
      margin-left: auto;
      margin-right: auto;
    }
    .servicesList1 button {
      margin-left: 1em;
    }
  }
  
  .serviceListButton {
    cursor: pointer;
  }
  