.delete {
  cursor: pointer;
  margin-left: 5%;
}

.edit {
  cursor: pointer;
}

.h4 {
  margin-left: 6.875rem;
  margin-top: 1.5em;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
}

.mainBox {
  margin-top: 1.625em;
  margin-left: 6.875rem;
  margin-right: 5.875em;
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
  /* width: 89%; */
}

.head {
  display: flex;
  padding-left: 2.125em;
  padding-right: 2em;
  padding-top: 1.8125em;
  justify-content: space-between;
}

.corporatecount {
  background-color: #e68d36;
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: 0.1875em;
  padding-bottom: 0.0625em;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 2%;
  color: #ffffff;
}

.addModalbtn {
  /* height: 70%; */
  /* margin-left: 55%; */
  text-align: center;
  margin-bottom: 1%;
  background-color: #319b4b;
  color: white;
  display: flex;

  justify-content: flex-end;
  /* margin-left: 75%; */
}

.addModalbtn1 {
  /* margin-left: 55%; */
  text-align: center;

  background-color: #319b4b;
  color: white;
  display: flex;
  justify-content: flex-end;
  /* margin-left: 75%; */
}

/* .list {
  width: 50%;
} */

.search {
  display: flex;
  margin-bottom: 2.0625em;
  margin-top: 2.1875em;
}

.modify {
  display: flex;
}

.corporatesearchBtn {
  border-radius: 6px;
  box-shadow: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none !important;

}
