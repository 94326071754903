.paymentHead {
  display: flex;
  padding-left: 2.125em;
  padding-right: 2em;
  padding-top: 1.8125em;
  justify-content: space-between;
}

.paymentrightheadbtn {
  display: flex;
  justify-content: space-between;
}

.updatePaymentBtn {
  margin-right: 1em;
  background-color: #5067a7;
}

.createLotBtn {
  background-color: #e68d36;
}

.paymentRow2 {
  display: flex;
  align-items: center;
  margin-left: 3.725em;
  justify-content: space-between;
  margin-right: 2em;
}

.paymentDate {
  display: flex;
  align-items: center;
}

.paymentStartDate input {
  margin-left: 0.725em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.paymentEndDate {
  margin-left: 1em;
}

.paymentEndDate input {
  margin-left: 0.725em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.paymentStatus_SEND_TO_ACCOUNT{
  color: #E68D36;
}

.paymentStatus_Paid{
  color: #44576A;
}

.paymentStatus_PENDING{
  color: #A53F2B;
}
