.ViewDiscountWrapper {
  padding: 3vw 8vw 3vw 8vw;
}

.ViewDiscountHeader {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  color: #1d2334;
  font-family: 'Nunito', sans-serif;
}

.ViewDiscountInfoSection {
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
  margin-top: 1.5vw;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
}

.ViewDiscountInfoIndividual {
  padding: 1.5vw 5vw 1.5vw 5vw;
  display: flex;
  font-family: 'Nunito', sans-serif;
}

.ViewDiscountInfo {
  width: 30%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}

.ViewDiscountData {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  color: #52608e;
  /* text-transform: capitalize; */
}

.AddDiscountInputField {
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 12px;
  font-family: "Nunito";
  font-style: normal;
  padding: 0.5em 1em 0.5em 1em;
  width: 22em;
}

.AddDiscountInputFieldDisabled {
  background: #dddddd;
  border: 1px solid #1d2334;
  border-radius: 12px;
  font-family: "Nunito";
  font-style: normal;
  padding: 0.5em 1em 0.5em 1em;
  width: 22em;
}

.AddDiscountInputFieldDescription {
  width: 35em;
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 12px;
  padding: 1em;
}

.AddDiscountSubmitButton {
  background: #319b4b;
  border-radius: 12px;
  color: #ffffff;
  padding: 0.7em 8em 0.7em 8em;
  border: none;
  cursor: pointer;
}

.SubmitButtonWrapper {
  padding-top: 1vw;
  padding-bottom: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ValidationAddDiscount {
  color: #ff3333;
  margin-left: 0.5em;
}
.seperaterCoupen {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  margin-left: 23vw;
}
.seperaterCoupen p {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #000000;
  margin-top: auto;
  margin-bottom: auto;
}
.seperaterCoupen1 {
  width: 40%;
  height: 0.1em;
  background-color: #1d2334;
}

.ViewDiscountButton {
  display: flex;
  justify-content: space-around;
  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: 15vw;
  margin-right: 15vw;
}

.ViewDiscountButtonEdit {
  background: #e68d36;
  border-radius: 12px;
  cursor: pointer;
  color: #ffffff;
  padding: 0.5em 5em 0.5em 5em;
  border: none;
}

.ViewDiscountButtonDelete {
  background: #a53f2b;
  border-radius: 12px;
  cursor: pointer;
  color: #ffffff;
  padding: 0.5em 5em 0.5em 5em;
  border: none;
}

.paymentRow2New {
  display: flex;
  align-items: flex-start;
  margin-left: 3.725em;
  justify-content: space-between;
  margin-right: 2em;
}
