.mainDivLifeStyle {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-right: auto;
  margin-top: 6vh;
  margin-left: auto;
  border-radius: 0.75rem;
  border: 1px solid #ede8e8;
  background: #fff;
  padding-bottom: 30vh;
  margin-bottom: "3em";
  }
.nameAndOtherText {
  width: 100%;
  position: relative;
}
.nameLifeStyle {
  padding: 0.6em 0em 0.6em 1em;
  color: var(--primary_color_1_1, #52608e);
  font-family: Rubik;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 40%;
}

.nameAndSelect {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3vh;
}
.nameLifeStyleValue{
  width: 60%;

}
.nameLifeStyleValue input {
  padding: 0.6em 0em 0.6em 1em;
  width: 100%;
border-radius: 6px;
border: 1px solid black;
}
.nameLifeStyleValue select {
  padding: 0.6em 0em 0.6em 1em;
  width: 100%;

}
.sameWidth {
  width: 10vw;
}
.selectNmaeLifeStyle {
  margin-top: 1.5vh;
  width: 25vw;
  padding-top: 1vh;
 padding-left: 3vw;
  padding-bottom: 1vh;
  border-radius: 0.375rem;
  border: 1px solid var(--primary_color_1_1, #52608e);
  background: var(--white, #fff);
  color: var(--primary_color_1_1, #52608e);
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.buttonOfLifeStyle {
  border-radius: 0.375rem;
  background: var(--primary_color_3, #319b4b);
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 0.6em;
  padding-bottom:0.6em;
  color: var(--secondary_color_3, #f2efea);
  font-family: Nunito;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: hidden;
  cursor: pointer;
  position: absolute;
  right: 2em;
  top:1.5em;
}
.nameLifeStylePackage {
  margin-left: 1em;
  color: var(--primary_color_1_1, #52608e);
  font-family: Rubik;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.chipsetTagAndName {
  margin-top: 5.5vh;
}
.chipsetTags {
  margin-left: 1em;
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
}
.chipDIvs{
  margin-left: 1em;
  /* width: 20%; */
  margin-top: 1em;
  margin-bottom: 1em;

}
