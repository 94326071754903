* {
    margin: 0px;
    padding: 0px;
  }
  .template {
    background: #e7eaed;
    display: flex;
    flex-direction: column;
  }
  .templateTop {
    width: 80%;
    margin-top: 2.06em;
    margin-left: auto;
    margin-right: auto;
    /* background-color: #319B4B; */
  }
  .templateTop span {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 2em;
    color: #1d2334;
  }
  .templateBottom {
    width: 80%;
    margin-top: 1.06em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* margin-right: 8.93em; */
  }
  .templateBottomLeft {
    /* width: 48.56em; */
    width: 70%;
    background: #ffffff;
    height: 48em;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0.75em;
  }
  .templateBottomRight {
    width: 30%;
    /* width: 30.5em; */
    background: linear-gradient(12.37deg, #52608e 0%, #18203a 100%);
    border: 1px solid #ede8e8;
    border-radius: 0.75em;
  }
  .addServicesHeading {
    width: 100%;
    height: 3em;
    background: transparent;
    padding-top: 1em;
    text-align: left;
    margin-bottom: 1em;
    margin-left: 1.5vw;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .addServicesHeading span {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 1000;
    font-size: 1.5em;
    color: #ffffff;
  }
  .allServices {
    height: 25.75em;
    overflow-y: auto;
  }
  .servicesList {
    margin-top: 1em;
    display: flex;
    align-items: center;
  }
  .servicesList button {
    margin-left: 2vw;
    width: 1.5vw;
    height: 1.5vw;
    display: flex;
    align-items: center;
    border: hidden;
    color: white;
    background: transparent;
    outline: none;
  }
  
  .servicesList button:hover{
  color: #D3D3D3;
  } 
  .servicesList span {
    /* margin-left: 1.3em; */
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    color: #ffffff;
  }
  .addTemplateSubmit {
    width: 90%;
    /* width: 79%; */
/* position: absolute; */
    margin-top: 5em;
    text-align: center;
    background: #e68d36;
    border-radius: 0.75em;
    height: 3em;
    margin-top: 3.87em;
    margin-left: auto;
  margin-right: auto;
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    bottom: 0;
  }
  .LabelDescription {
    margin-top: 12px;
  }
  .addTemplateSubmit span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.1em;
    color: #f2efea;
  }
  .addTemplateSubmit span:hover {
    cursor: pointer;
  }
  .inputFieldsContainer1 {
    display: flex;
    flex-direction: column;
    /* margin-left: 6.875em; */
    /* margin-top: 4.375em; */
    margin-top: 3.5625em;
  }
  
  .errorMessageModalValidation1 {
    color: rgb(163, 45, 24);
    margin-left: 10%;
    margin-top: 1.125em;
    /* margin-bottom: 0.333em; */
  }
  @media screen and (max-width: 1300px) {
    /* .addServicesHeading{
        padding-left: 1em;
        font-size: .9em;
        text-align: center;
    } */
    .templateTop {
      margin-left: auto;
      margin-right: auto;
    }
    .templateBottom {
      margin-left: auto;
      margin-right: auto;
    }
    .servicesList button {
      margin-left: 1em;
    }
  }
  .formControlProgram {
    width: 99%;
    margin-left: 4em;
    margin-top: 2em;
  }
  .textingArea {
    margin-left: 4em;
    margin-top: 2em;
    width: 31em;
  }
  
  .addServicesSeperator {
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: 2px solid #f2efea;
    margin-bottom: 1em;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 12px !important;
}
/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  border: 1px solid black !important;
  border-radius: 12px !important;
} */