.employee_view {
  width: 100%;
  height: 110vh;
}

.employeeDet {
  width: 85%;
  height: 10%;
  /* background-color: #a53f2b; */
  margin-left: auto;
  margin-right: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 150%;
  color: #1d2334;
  display: flex;
  align-items: center;
}

.employeeDet p {
  margin-top: auto;
  margin-bottom: auto;
}

.main_box {
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 0.75rem;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 0; */
  height: 90%;
}

.box-head {
  width: 100%;
  height: 10%;
  border-bottom: 1px solid #ecebeb;
  /* background-color: yellowgreen; */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.12vw;
  line-height: 150%;
  color: #1d2334;
  display: flex;
  align-items: center;
  padding-left: 3%;
}

.box-bodyy {
  width: 100%;
  height: 30%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.box_detail {
  height: 15%;
  /* background-color: tomato; */
  display: flex;

  /* justify-content: space-between; */
}
.question {
  height: 100%;
  width: 18%;
  /* background-color: #e68d36; */
  padding-left: 3%;
  display: flex;
  align-items: center;
}
.answer {
  height: 100%;
  /* background-color: teal; */
  /* width: 30%; */
  display: flex;
  align-items: center;
}
.question p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 150%;

  color: #1d2334;
}
.answer p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  color: #1d2334;
}
.program_Assigned {
  border-top: 1px solid #ecebeb;
  width: 100%;
  height: 10%;
  /* background-color:cadetblue; */
  display: flex;
  align-items: center;
}
.program_Assigned p {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 3%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.12vw;
  line-height: 150%;
  color: #73757a;
}

.employeeTable {
  width: 100%;
  height: 50%;
}
.name {
}

.status {
  background-color: #e68d36;

  border-radius: 6px;
}

.edit {
  color: blue;
}

.desc {
  font-weight: 400;
}

.btnDeletes {
  border-radius: 12px;
  background: #a53f2b;
  color: white;
}

.editBtn {
  border: none;
  outline: none !important;

  background-color: white;
  color: blue;
  cursor: pointer;
}
