.ModalFilterProduct{
    position: absolute;
    width: 80%;
    /* min-height: 80vh; */
    background-color: white;
    top: 15%;
    left: 0 ;
    right: 0 ;
    /* bottom: 15%; */
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
border-radius: 12px;
border: 2px solid #CEC9C9;
backdrop-filter: blur(2px);
}
.filterSelected{
    width: 95%;
}
.filterSelectedChild{
    border: .6px solid #52608E;
    margin-top: 1em;
    border-radius: 6px;
}
.filterSelectedChild p{
    color: var(--primary-color-11, #52608E);
    font-family: Rubik;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
    margin-top: 1em;
    margin-left: 1em;
}
.filterSelectedChildList{
    display: flex;
    flex-wrap: wrap;
}