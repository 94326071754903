
.mainCareer{
    width: 100%;
    /* background-color: aqua; */
  /* height: 100vh; */
   display: flex;
   flex-direction: column;
   align-items: center;
   
}
.middleCareerBox{
    width: 90%;
    height: 1000px;
    /* height: 100%; */
    /* background-color: #292828; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* position: absolute; */
    /* position: relative; */
}
.careerImage{
    width: 50%;
    
  position: relative;
    /* height: 45%; */
    min-height: 400px;
    background-image: url("../../../../assets/Ellipse3.png");
   display: flex;
   flex-direction: column;
   align-items: center;
   
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.nurse{
    position: absolute;
    width: 70%;
    height: 80%;
    bottom: 0;
    margin: auto auto;
}
.circle{
    
    position: absolute;
    width: 80%;
    height: 75%;
    top: 0;
}
.careerBox{
    /* position: absolute; */
    position: relative;
    width: 100%;
    /* height: 62.5%; */
    max-height:150rem;
    /* height: 100%; */
    bottom: 10%;
    z-index: 1;
    /* top: 30%; */
    /* background-color: yellowgreen; */
    
    background: #FFFFFF;
box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
border-radius: 16px;
}
.careerInfo{
    width: 90%;
    /* height: 35%; */
    /* min-height: 200px; */
    /* background-color:yellow ; */
    margin:auto auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.careerInfoChild1{
    font-family: 'Nunito';
font-style: normal;
font-weight: 800;
font-size: 2.5vw;
line-height: 150%;
/* background-color: aqua; */
    padding-top: 1em;
color: #2D2424;
}
.careerInfoChild2{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: #292828;
    
}
.serviceCard{
    width: 90%;
    /* height: 45%; */
    /* min-height: 300px; */
    /* background-color: steelblue; */
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.serviceCardChild1 {
    /* height: 30%; */
    margin-top: 1.5em;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FBFDFF;
box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
border-radius: 16px;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1.5vw;
line-height: 150%;
color: #2D2424;
}
.serviceCardChild1 p{
    margin-left: 3%;
    margin-top: auto;
    margin-bottom: auto;
}
.serviceCardChild1 img{
    margin-right: 5%;
}
.serviceCardChild2 {
    /* height: 30%; */
    margin-top: 1.5em;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FBFDFF;
box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
border-radius: 16px;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1.5vw;
line-height: 150%;
color: #2D2424;
}
.serviceCardChild2 p{
    margin-left: 3%;
    margin-top: auto;
    margin-bottom: auto;
}
.serviceCardChild2 img{
    margin-right: 5%;
}
.Help{
   width: 90%;
  

   /* height: 20%; */
   /* min-height: 130px; */
   margin: auto auto;
   /* background-color: tomato; */
   display: flex;
   flex-direction: column;
   justify-content: space-evenly; 
}
.helpChild1{
    padding-top: 1em;
    font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 2vw;
line-height: 150%;

color: #292828;
}
.helpChild2{
    font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1.5vw;
line-height: 150%;
color: #292828;
padding-bottom: 2em;
}

.expand{
    
    /* width: 95%; */
    margin: auto auto;
    margin-top: 2em;
    height: 50rem;
display: flex;
flex-direction: column;
background: #FBFDFF;
box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
border-radius: 16px;
}
.headingArea1{
    height: 10%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headingArea1 p{
    
    padding-left: 3%;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1.5rem;
line-height: 150%;
color: #2D2424;
}
.headingArea1 img{
    padding-right: 3%;
    margin-top: auto;
    margin-bottom: auto;
}
.headingArea2{
    height: 20%;
    /* background-color: yellowgreen; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.headingArea2 :nth-child(1){
    font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 1.75vw;
line-height: 150%;
color: #292828;
padding-left: 3%;
}
.headingArea2 :nth-child(2){
    font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1rem;
line-height: 150%;
color: #292828;
padding-left: 3%;
}
.headingArea3{
    height: 30%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.headingArea3 p{
    padding-left: 3%;
    font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 1.75vw;
line-height: 150%;
color: #292828;
}
.headingArea3 li{
    padding-left: 3%;
    font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1rem;
line-height: 150%;
color: #292828;
list-style-image: url("../../../../assets/bulletPoint.png");
list-style-position: inside;
}
.headingArea4{
    height: 30%;
    /* background-color: violet; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.headingArea4 p{
    padding-left: 3%;
    font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 1.75vw;
line-height: 150%;
color: #292828;
}
.headingArea4 li{
    padding-left: 3%;
    font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1rem;
line-height: 150%;
color: #292828;
list-style-image: url("../../../../assets/bulletPoint.png");
list-style-position: inside;
}
.headingArea5{
    height: 10%;
    /* background-color: tomato; */
    display: flex;
    align-items: center;
}
.headingArea5 p{
    padding-left: 3%;
    font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 1.75vw;
line-height: 150%;
color: #292828;
margin-top: auto;
margin-bottom: auto;
}