.mainBox{
    position: relative;
}
.bannerHead {
    display: flex;
    flex-direction: column;
    position: relative;

}

.bannerMain {
    display: flex;

}

.bannerRow {
    display: flex;
    justify-content: space-between;
}

.bannerRow button {
    position: absolute;
    right: 5%;
    bottom: 5%;
    border-radius: 6px;
    color: white;
    padding: 0.6em 1em;
    border: none;
    background-color: #319B4B;
}

.banner-value {
    margin-left: 10%;
}

body {
    background-color: #EDE8E8;
}

.BannerListing {
    display: flex;
    flex-direction: column;
}

.BannerListingSection1 {
    margin-left: 4.2em;
    margin-right: 4.2em;
}

.BannerListingSection1 p {
    padding-top: 1em;
    padding-bottom: 1em;
    font-family: Rubik;
    font-size: 2em;
    font-weight: 600;
}

.BannerListingSection2 {
    margin-left: 4.2em;
    margin-right: 4.2em;
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #EDE8E8;
    display: flex;
    flex-direction: column;
margin-bottom: 2em;
}

.BannerListingSection2_1 {
    border-bottom: 1px solid #EDE8E8;
    padding-top: 1.5em;
    padding-bottom: 1.5em;

}

.BannerListingSection2_1 select {
    width: 7em;
    margin-left: 2em;
    padding-top: .5em;
    padding-bottom: .5em;
    border: 1px solid #1D2334;
    border-radius: 6px;
    background: #FFF;
    padding-left: 1em;
    -webkit-appearance: none;
  -moz-appearance: none ;
    background-image: url("../../../assets/downArrowBlack.png");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) center;
}



.BannerListingSection2_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    margin-bottom: 3em;
}

.BannerListingSection2_2Children {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    /* background-color: #52608E; */
    /* border-radius: 6px; */
    margin-top: 1.3em;
    Border: 1px solid #EDE8E8;
}

.BannerListingSection2_2ChildrenRight {
    width: 35%;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.upArrowImage {
    width: 13px;
    height: 8px;
    cursor: pointer;
    margin-right: 1em;
}

.BannerListingSection2_2ChildrenLeft {
    width: 65%;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 10px solid #52608E;
}

.BannerListingSection2_2ChildrenLeft p {
    font-family: Rubik;
    font-size: 1em;
    font-weight: 400;
    line-height: 24px;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 1em;
    color: #52608E;
}

.BannerListingSection2_2ChildrenRight button {
    height: 60%;
    padding-left: 1em;
    padding-right: 1em;
    font-family: Rubik;
    font-size: 1em;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
    background-color: #3C9C4B;
    border: hidden;
}

.dropdownBanner {
    width: 95%;
    background-color: #52608E;
    display: flex;
    flex-direction: column;
    margin-top: -.2em;
    margin-left: .1em;
}

.dropdownHeader {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    border-left: 10px solid #52608E;
}

.dropdownHeaderChild {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color-3, #F2EFEA);
    font-family: Nunito;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 1em;
    padding-bottom: 1em;
}
.dropdownHeaderChildModify{
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--secondary-color-3, #F2EFEA);
    font-family: Nunito;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 2em;
}
.dropdownHeaderChildName {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color-3, #F2EFEA);
    font-family: Nunito;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 1em;
    padding-bottom: 1em;
}

.dropdownHeaderValue {
    background-color: #ffffff;
    display: flex;
    justify-content: space-evenly;
    border-left: 10px solid #52608E;
    /* border-radius: 6px; */
    color: #52608E;
}

.dropdownValueChild {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color-3, #F2EFEA);
    font-family: Nunito;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #52608E;
}
.dropdownValueChildModify{
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--secondary-color-3, #F2EFEA);
    font-family: Nunito;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #52608E;
}

.dropdownValueChildName {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color-3, #F2EFEA);
    font-family: Nunito;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #52608E;

}

.dropdownNoBanner {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 10px solid #52608E;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #EDE8E8;
    border-right: 1px solid #EDE8E8;

}
.typecAddButton{
    position: absolute;
    /* margin-left: 85%; */
    right: 5%;
    margin-top: 1.5em;
    background: var(--primary-color-3, #319B4B);
    border-radius: 6px;
    color: white;
    padding: 0.6em 1em;
    border: none;
    width: 10em;
}
.typcBannerMain{
    width: 100%;
}
.infoMentalHealth{
    margin-right: 1em;
}

.BannerHint {
    display: inline-block;
    margin-left: 3em;
  }

  .BannerHint .BannerHintDescriptionText {
    visibility: hidden;
    width: 30vw;
    background-color: black;
    color: #fff;
    text-align: left;
    padding: 5px 2px 2px 1em;
    border-radius: 6px;
    position: absolute;
    z-index: 9999;
  }

  .BannerHint:hover .BannerHintDescriptionText {
    visibility: visible;
  }
