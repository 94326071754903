.parentDiagnostic {
  width: 100%;
}
.diagnostic {
  /* background: #1D2334; */
  width: 79%;
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 0.75em;
  margin-bottom: 2.6em;
  /* padding-top: 0.75em; */
  padding-bottom: 0.75em;
  /* padding-left: 1.62em; */
  margin-top: 2.6em;
  margin-left: 3.81em;
  margin-right: 6.06em;
  margin-bottom: 3.6em;
}
.inputlabel {
  /* background-color: rgb(255, 51, 0); */
  width: 50%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  margin-left: 1.5em;
}

.diagnostic_top {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  /* background-color: aqua; */
}
.placeholder {
  color: white;
}

.diagnostic_top .Diagnostic-heading {
  width: 25%;
  font-size: 1.12em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  color: #1d2334;
  padding-left: 1em;
  padding-top: 0.8em;
  /* background-color: blue; */
}
.diagnosticClose {
  height: 30px;
  position: absolute;
  right: 0.48em;
  top: 25px;
  background-color: #ffffff;
  border: hidden;
  outline: none !important;
  cursor: pointer;
}
.diagnostic_bottom {
  /* width: 100%; */
  display: flex;
}
.bottom_left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.bottom_right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
}
.parent_placeholder {
  height: 0px;
  /* background-color: brown; */
  position: absolute;
  left: 5%;
}
.package_attribute {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 1.25em;
  height: 3em;
  align-items: center;
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 0.5em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: .9em;
  line-height: 1.5em;
  text-align: right;
  color: #1d2334;
  margin-bottom: 0.2em;
  /* background-color: aqua; */
}
.package_attribute input {
  width: 50px;
  height: 30px;
  /* font-weight: bold; */
  font-size: 1em;
  border: 1px solid #1d2334;
  margin-right: 1em;
  margin-top: auto;
  margin-bottom: auto;
  /* background-color: blue; */
}
.package_attribute span {
  margin-left: 10px;
  font-weight: bold;
  text-align: left;
  width: 70%;
}
.leftside{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
 .rightside {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  /* background-color: chartreuse; */
}
.center{
  position: absolute;
  right: 0em;
  height: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
}
.package_attribute .rightside span {
  margin-right: 20px;
}

.test_attribute {
  display: flex;
  justify-content: space-between;
  width: 13.87em;
  margin-top: 1.25em;
  height: 2.5em;
  align-items: center;
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 0.5em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.5em;
  text-align: right;
  color: #1d2334;
  margin-bottom: 0.2em;
}
.test_attribute input {
  width: 3.12em;
  height: 1.56em;
  font-weight: bold;
  font-size: 1em;
  border: 1px solid #1d2334;
  margin-right: 1em;
  margin-top: auto;
  margin-bottom: auto;
}
.test_attribute span {
  margin-left: 10px;
  font-weight: bold;
}
.test_attribute .rightside {
  display: flex;
}
.test_attribute .rightside span {
  margin-right: 20px;
}

/* @media screen and (max-width:1600px) {
    .test_attribute{
        width: 90%;
    }
    .package_attribute {
        width: 90%;
    }

    .diagnostic_top .Diagnostic-heading{
        width: 100%;
        
    }
    .inputlabel{
        width: 49%;
        margin-left: .4%;
    }
    .test_attribute .package_attribute{
        margin-bottom: 0.2em;
    }

} */
@media screen and (max-width: 850px) {
  .diagnostic_top .Diagnostic-heading {
    width: 100%;

    /* margin-left: -2.5em; */
  }
  .inputlabel {
    width: 100%;
    /* margin-left: .4%;  */
    margin-top: 20px;
    /* margin-left: -2em;  */
  }
  .bottom_left {
    width: 100%;
    justify-content: center;
  }
  .bottom_right {
    width: 100%;
    justify-content: center;
  }
  .package_attribute {
    width: 80%;
  }
  .diagnostic_bottom {
    flex-wrap: wrap;
  }
  .bottom_right {
    width: 100%;
    justify-content: center;
  }
  .test_attribute {
    width: 80%;
    margin-bottom: 0.2em;
  }
  .bottom_left .placeholder {
    width: 40%;
    margin-left: 10px;
    color: gray;
    font-family: bold;
  }
  .bottom_right .placeholder {
    width: 40%;
    margin-left: 10px;
    color: gray;
    font-family: bold;
  }
  .parent_placeholder {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .diagnostic_top {
    position: relative;
  }
  .diagnosticClose {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
}
/* @media screen and (max-width:1200px){
    .diagnostic{
        padding-left:1em ;
       
    }
    
    
}
@media screen and (max-width:1625px) {
    .diagnostic_top .Diagnostic-heading{
        width: 100%;
        
    }
    .inputlabel{
        width: 49%;
        margin-left: .4%;
    }
   
    .closeButtonModal{
        position: absolute;
        top:5px;
        right: 5px;
    }
    


} */
/* @media screen and (max-width:900px) {
    .diagnostic_top .Diagnostic-heading{
        width: 100%;
        font-size: 1rem;
    }
    .inputlabel{
        width: 100%;
        margin-left: .4%;
    }
   
    .closeButtonModal{
        position: absolute;
        top:5px;
        right: 5px;
    }
    .diagnostic_bottom{
        flex-direction: column;
    }
    .bottom_left{
        width: 100%;
        justify-content: center;
    }
    .package_attribute{
        width: 70%;
    }
    .bottom_right{
        width: 100%;
        justify-content: center;
    }
    .bottom_left .placeholder {
        width: 40%; 
        margin-left: 10px;
        color: gray;
        font-family: bold;
    }
    .bottom_right .placeholder {
        width: 40%; 
        margin-left: 10px;
        color: gray;
        font-family: bold;
    }
    .parent_placeholder{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .test_attribute{
        width: 70%;
    }
    .diagnostic{
        width:40%;
    }
    
}  
@media screen and (max-width:1610px) {
     .diagnostic{
        margin-left: 3.8em;
        width: 75%; 
    }  
    .test_attribute{
        width: 90%;
    }
    .package_attribute{
        width: 90%;
    } */
/* .bottom_right .placeholder {
        width: 40%; 
        margin-left: 10px;
        color: gray;
        font-family: bold;
    }
    .bottom_left .placeholder {
        width: 40%; 
        margin-left: 10px;
        color: gray;
        font-family: bold;
      
    } */
/* .package_attribute input{
        margin-top: .1em;
    }
    
    .closeButtonModal{
        position: absolute;
        top:-3em;
        
    }
}
@media screen and (max-width:1200px) {
     .diagnostic{
        width:73%
    }
    .diagnostic_bottom{
        flex-direction: column;
    }
    .bottom_left{
        width: 100%;
    }
    .bottom_right{
        width: 100%;
    }
    
}  */
