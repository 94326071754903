.DisableModalHeading {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 150%;
  margin: auto;
  color: #000000;
  text-align: center;
}

.fileError{
  color: rgb(163, 45, 24);
}

.DisableModalSubHeading {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  text-align: center;

}

.disableReasontextarea {
  width: 48vw;
  height: 150px;
  margin-top: 1vw;
  border: 1px solid #1D2334;
  border-radius: 0.75em;
  color: #1D2334;
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-size: 1em;
  /* margin-left: 137px; */
  padding-top: 0.75em;
  padding-bottom: 4.06em;
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.Responsiveyu {
  text-align: center;
  /* display: flex; */

}

.DisableModalUpload {
  width: 48vw;
  height: 12.38vw;
  left: 437px;
  top: 625px;
  text-align: center;
  background: #FFFFFF;
  border: 1px dashed #000000;
  /* margin-left: 11vw;
    margin-top: 1vw; */
  margin: 1vw auto;
}

.disableModalSubmitButton {
  display: flex;
  justify-content: center;
}

.disableModalButton {
  width: 25vw;
  height: 2.5em;
  background: #44576A;
  border-radius: 12px;
  color: white;
  border: none;
  outline: none !important;
  margin-bottom: 5%;
  margin-top: 3em;
  cursor: pointer;
}

.uploadSignMain {
  margin-top: 2vw;
}

.fileUploadContainer {
  width: 15vw;
  margin-left: 16vw;
  margin-top: 1vw;

}

input[type='file'] {
  color: grey;
  width: 15vw;
}

::-webkit-file-upload-button {
  color: #838383;
  background: #DFDFDF !important;
  padding: 5px;
  border: none;
  outline: none !important;

  border-radius: 10px;
  /* box-shadow: 1px 0 1px 1px #6b4559; */
  outline: none;
  height: 100%;
  cursor: pointer;
}

/*  
  .css-79ws1d-MuiModal-root {
    z-index: 1 !important;
   
} */

.errorDisableModalValidation {
  color: rgb(163, 45, 24);
  margin-left: 1%;
  /* margin-top: 0.vw; */
  margin-bottom: 1vw;
}

.errorDisableModalValidation1 {
  color: rgb(163, 45, 24);
  margin-left: 1%;
  margin-top: 2.5vw;
  margin-bottom: 1vw;
}

.errorMessageModalValidationhosp {
  color: rgb(163, 45, 24);
  margin-left: 1%;
  margin-top: -0.875em;
  margin-bottom: 0.4375em;
}

.selectFileMessage {
  color: #605b5b;
}