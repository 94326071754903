.textbox {
  display: flex;
  flex-direction: column;
}

/* .text1{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 1.62em;
    margin-left: 3.81em;
    margin-top:2.62em;
    margin-right:6.06em;
    background: #FFFFFF;
border: 1px solid #1D2334;
border-radius: 0.75em; 
}
.text2{
 
    padding-bottom: 3.25em;
    margin-left: 3.81em;
    margin-right:6.06em;
    margin-top: 1.25em;
    background: #FFFFFF;
border: 1px solid #1D2334;
border-radius: 0.75em; 
} */
/* .textarea1{ 
    width: 80%;
    
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    }
.textarea2{
 
width: 84%;
margin-left:1.62em;
margin-top: 1.18em;
padding-top: 1.18em;
padding-bottom: 3.25em;
font-family: 'Nunito';
font-style: italic;
font-weight: 400;
font-size: 1em;
line-height: 1.5em;
} */
.textbox {
  /* padding-left: 3.81em; */

}

.text {
  width: 100%;

}

.textarea1 {
  width: 79%;

  border: 1px solid #1D2334;
  border-radius: 0.75em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 1.62em;

  border-radius: 0.75em;
  margin-top: 1.62em;
  color: #1D2334;
  font-family: 'Nunito';
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;
  margin-left: 3.81em;

}

.textarea2 {
  width: 79%;
  margin-top: 2.3em;
  border: 1px solid #1D2334;
  border-radius: 0.75em;
  color: #1D2334;
  font-family: 'Nunito';
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;
  margin-left: 3.81em;
  padding-top: 0.75em;
  padding-bottom: 4.06em;
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.textarea2::placeholder{
  color: #1D2334;
}

.textarea1::placeholder{
  color: #1D2334;
}


/* .textingarea1{
    width: 50%;

    border: 1px solid #1D2334;
  border-radius: 0.75em;
    padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 1.62em;
  
  border-radius: 0.75em;
  margin-top:1.62em;
    color: #1D2334;
    font-family: 'Nunito';
font-style: italic;
font-weight: 400;
font-size: 1em;
margin-left: 3.81em;
} */