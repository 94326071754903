.ViewUserMain {
  padding: 2vw 6.18vw;
}

.ViewUserMain h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  color: #1d2334;
}

.ViewUserWrapper {
  background-color: #ffffff;
}

.ViewUserPart1Section1 {
  display: flex;
  justify-content: space-between;
  padding: 2.49vw;
  align-items: center;
}

.ViewUserPart1Section1Header {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #1d2334;
  text-transform: capitalize;
}

.ViewUserPart1Section1Select {
  background: #ffffff;
  border: 1px solid #52608e;
  border-radius: 6px;
  padding: 0.5rem 1rem ;
}

.ViewUserPart1Section1Header2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #1d2334;
  padding: 2.49vw;
}

.ViewUserPart1Section2 {
  padding: 1.5vw 2.49vw;
  position: relative;
}

.ViewUserSepertaor {
  padding: 0px;
}

.ViewUserPart1Section2Details {
  display: flex;
  margin-bottom: 1rem;
}

.ViewUserPart1Section2Details h3 {
  width: 23vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}

.ViewUserPart1Section2Details p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}

.ViewUserPart3NoMessage{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #AD343E; 
  padding: 0.49vw 2.49vw 1.49vw 2.49vw;
}
.verifyKycButton{
  background: #319B4B;
    border-radius: 6px;
    padding: 0.7em 1.6em 0.7em 1.6em;
    margin-right: 2.12em;
    border: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 22px;
    color: #F2EFEA;
    position: absolute;
    right: 1em;
}