.viewDetailImages{
    width: 90%;
    margin: 0 auto;
}
.viewDetailImages p{
    font-family: Nunito;
    font-family: Rubik;
font-size: 1em;
font-weight: bold;
line-height: 24px;
letter-spacing: 0em;
}
.viewDetailImagesArea{
    /* width: 90%; */
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
}
.viewDetailImagesArea img{
    margin-left: 1em;
    margin-top: 1em;
}
.ViewProductDetailPricing{
    display: flex;
    width: 30%;
    background: #FFFFFF;
border: 1px solid #1D2334;
border-radius: 8px;
margin-top: 1.1em;
margin-left:1em;
margin-bottom: 1em;
}

.productDetailsMain{
    width: 95%;
    display: flex;
    float: right;
    margin-bottom: 1em;
}
.productDetailsLeft{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
font-family: Rubik;
font-size: 1em;
font-weight: bold;
line-height: 24px;
letter-spacing: 0em;

}
.productDetailsLeftDescription{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
}
.productDetailsRight{
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Rubik;
font-size: 1em;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
}
.productDetailsRight p{
    margin-bottom: 0em;
}
.searchAreaViewCategory{
    display: flex;
    width: 100%;
}
.hospitalDoctorCategory{
    width: 60%;
font-family: Rubik;
font-size: 1em;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
display: flex;
align-items: center;
padding-left: 1em;
}
.searchSectionCategory{
/* width: 20%; */
}
.searchBar{
    width: 100%;
}
.rightheadbtnCategory{
    width: 20%;
    display: flex;
justify-content: flex-end;
padding-right: 1em;
}
.rightheadbtnCategory button{
    height: 100%;
    padding: 0.6em 1em 0.6em 1em;
    background: #319B4B;
color: #fff;
border: hidden;
border-radius: 6px;
}