.dashboard{
    height: 100vh;
    background-color: #E7EAED;
    width: 100vw;
}

.dashmain{
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 2vh;
    padding-bottom: 4vh;
}

.appointment{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summaries{
    display: flex;
    justify-content: flex-start;
}