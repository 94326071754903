.bookingStyle1{
 
    position: "absolute";
    /* margin-top: 6.5%; */
    /* top: 12.5%; */
    margin-left: 4%;
    margin-right: 4%;
padding-bottom: 4%;
    left: 0%;
    /* width: 52.56em; */
    /* height: 100vh; */
    background-color: white;
    border: "2px solid #000";
    box-shadow: 24;
    border-radius: 12px 12px 12px 12px;
  
}
.headingBooking{
    margin-top: 2em;
    margin-left: 4%;
    font-size: 32px;
    font-family: 'Nunito';
font-style: normal;
font-weight: 700;
}

.modalHeaderBooking{

}
.inputFieldBody{
    padding-top: 1%;
}

.Dropdown_dropdown__ZbOaq .Dropdown_menu__ucc97 {
/* position: relative !important; */
margin-top: -3.3vw !important; 
width: 33vw !important;
}

@media screen and (min-width:900px) {

    .Dropdown_dropdown__ZbOaq .Dropdown_menu__ucc97 {
        /* position: relative !important; */
        margin-top: -2.2vw !important; 
        width: 33vw !important;
        }
}

/* .
element.style {
} */
.Dropdown_button__HvVDU.Dropdown_button-secondary__-1SjD
{
    padding-left: 1.625em !important;
    width: 40% !important;
    height: 2.5em !important;
    border: 1px solid #52608e  !important;
    border-radius: 12px !important;
    background-color: white !important;
    margin-bottom: 2.6875em !important;
    font-family: "Nunito" !important;
    /* font-style: italic; */
    font-weight: 400 !important;
    font-size: 1em!important;
    line-height: 150% !important;
}

.Dropdown_button__HvVDU.Dropdown_button-secondary__-1SjD {
    display: flex !important;
    justify-content: flex-start !important;

}

.Dropdown_dropdown__ZbOaq {
display: flex !important;
flex-direction: column !important;
}

.Submenu_submenu__RtC6b {
left: 100% !important;
top: 0px !important;
right: auto !important;
}

.planBody{    position: absolute;
    margin-top: 50vw;
    display: flex;
    margin-left: 37vw;}

    .Dropdown_button__HvVDU.Dropdown_button-secondary__-1SjD {
        width:100% !important;

    }
    .Dropdown_dropdown__ZbOaq {
    width: 40% !important;
    }