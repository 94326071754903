.createLotAmount {
  margin-left: 5.625em;
  margin-bottom: 5.625em;
  display: flex;
}

.amount {
  display: flex;
}
.amount .h5 {
  margin-left: 0.5em;
  font-size: 3vh;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.consultation {
  display: flex;
}
.consultation .h5 {
  margin-left: 0.5em;
  font-size: 3vh;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.MuiDataGrid-columnHeaderTitleContainer {
  background-color: #52608e;
  color: white;
}
.PrivateSwitchBase-input css-1m9pwf3 {
  color: white;
}
/* .css-i4bv87-MuiSvgIcon-root {
  color: black;
} */

.lotCreateBtn {
  padding: 0.225em 2em;
  background-color: #52608e;
  color: white;
  border: none;
  outline: none !important;
  margin-left: 7vw;
  height: 3em;
  cursor: pointer;
  border-radius: 5px;
}

.paymentLotlist {
  margin-left: 1.125em;
}
