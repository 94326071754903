.cashlessOpd {
    width: 79%;
    border: 1px solid #1D2334;
    border-radius: 0.75em;
    display: flex;
    flex-direction: column;
  flex-wrap: wrap;
  margin-top: 2.6em;
  margin-left: 3.81em;
  margin-right: 6.06em;
  margin-bottom: 3.6em;
  }
  .cashlessOpd-top {
    display: flex;
    justify-content: center;
    height: 4em;
    /* margin-top: 5px; */
    /* background-color: aqua; */
    position: relative;
  }
  .section1 {
    width: 80%;
    font-size: 1.12em;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1D2334;
    /* background-color: rgb(43, 255, 0); */
  }
  /* .section2 {
    width: 50%;
    margin-top: .5em;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .section3 {
    position: absolute;
    right: 0;
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right:1em;
  }
  .cashlessOpd-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .templateClose {
    width: 40px;
    /* margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: hidden;
    outline: none !important;
    cursor: pointer;
  }
  .services_attribute_new {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 20px;
    height: 3em;
    align-items: center;
    background: #ffffff;
    border: 1px solid #1D2334;
    border-radius: 8px;
    margin-bottom: 20px;
    color: #1D2334;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5em;
  }
  .services_attribute_new .leftside {
    /* width: 50%;
    display: flex;
    justify-content: center;
    align-items: center; */
padding-left: .6em;

  }
  .services_attribute_new .rightside {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
  .services_attribute_new input {
    width: 50px;
    height: 30px;
    border: 1px solid #1D2334 ;
    font-size: 1em;
  }
  @media screen and (max-width:1110px){
    .cashlessOpd-top{
      flex-direction: column;
    }
    .cashlessOpd{
      position: relative;
    }
    .section1{
      width: 100%;
    }
    .section2{
      width: 100%;
    }
    .section3{
      position: absolute;
    right:0;
    }
    .templateClose{
      position: absolute;
      top:.5em;
    }
  }
  @media screen and (max-width:850px){
    .services_attribute_new{
      width: 80%;
    }
    .services_attribute_new input{
      height: 2em;
      border: 1px solid #1D2334;
    }
  }
  .rightside input{
    border-radius: 6px;
    width: 4vw;
    padding-left: .2em;
  }
  .pharmacyService {
    width: 79%;
    border: 1px solid #1D2334;
    border-radius: 0.75em;
    display: flex;
  flex-wrap: wrap;
  margin-top: 2.6em;
  margin-left: 3.81em;
  margin-right: 6.06em;
  margin-bottom: 3.6em;

  }
  .pharmacyService1{
    width: 30%;
    font-size: 1.12em;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1D2334;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .pharmacyService2{
    width: 60%;
    display: flex;
    
  }
  .pharmacyService2Child1{
    display: flex;
    justify-content: space-around;
align-items: center;
    width: 50%;
  }
  .pharmacyService2Child1 p{
    margin-top: auto;
    margin-bottom: auto;
    font-family: Rubik;
font-size: 0.7em;
font-style: normal;
font-weight: 400;
line-height: 150%;
  }
  .pharmacyService2Child1 input{
    width: 35%;
    height: 55%;
    font-family: Rubik;
font-size: 0.7em;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: .5em;
  }
  .pharmacyService3{
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right:.3em;
  }