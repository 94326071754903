.button {
  background: #319b4b;
  border-radius: 6px;
  border: none;
  outline: none !important;

  padding-top: 4%;
  padding-bottom: 4%;
  width: 120%;
  color: white;
  cursor: pointer;
}

.btnCreate {
  margin-left: 42%;
}

.card-box {
  display: grid;
  width: 100%;
}

.h4 {
  margin-left: 6%;
  margin-top: 2%;
  font-family: "Nunito", sans-serif;
}

.mainBox {
  margin-top: 1.625em;
  margin-left: 6.875rem;
  margin-right: 5.875em;
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
  /* width: 89%; */
}

.head {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
}

.count {
  background-color: #e68d36;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  padding-bottom: 1px;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 2%;
}

.btn {
  /* margin-left: 60%; */
  text-align: center;
  margin-bottom: 1%;
  background-color: #319b4b;
  color: white;
}

/* .list {
  width: 20%;
} */

.search {
  display: flex;
  margin-bottom: 3%;
  margin-top: 2%;
}

.searchBox {
  margin-left: 10%;
}

.autocomplete {
  width: 20%;
}

.modify {
  display: flex;
}

.box-body {
  display: flex;
  width: 100%;
}

.cardsPP {
  cursor: pointer;
  background-color: white;
  margin-left: 7%;
  border: 1px solid white;
  border-left: 10px solid #52608e;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  min-width: 25%;
  max-width: 150%;
  margin-top: 5%;
  margin-bottom: 5%;
  cursor: pointer;
}
.cardsPP:hover{
  border-left: 10px solid green;
}
.cardsPPNew {
  cursor: pointer;
  background-color: white;
  margin-left: 7%;
  border: 1px solid white;
  border-left: 10px solid #52608e;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  min-width: 25%;
  max-width: 150%;
  margin-top: 5%;
  margin-bottom: 5%;
  cursor: pointer;
}
.cardsPPNew:hover{
  border-left: 10px solid red;
  cursor: default;

}
.card-headPP {
  margin-top: 5%;
  margin-left: 15%;
  font-size: 16px;
  font-weight: 700;
  color: #52608e;
}

.card-body {
  margin-left: 9%;
  color: #1d2334;
  font-size: 14px;
}

.card-edit {
  margin-left: 50%;
  cursor: pointer;
}

.card-text {
  margin-top: 2%;
}

.custom-card {
  background-color: white;
  margin-left: 7%;
  border: 1px solid white;
  border-left: 10px solid #319b4b;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  min-width: 25%;
  max-width: 150%;
  margin-top: 2%;
}

.custom-card-head {
  display: flex;
  margin-top: 5%;
  margin-left: 15%;
  width: max-content;
  font-size: 16px;
  color: #319b4b;
}

.card-bodyPP {
  margin-left: 9%;
  color: #52608e;
  font-size: 14px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: 5%;
  margin-left: 15%;
}

.card-edit {
  margin-left: 50%;
}

.card-text {
  margin-top: 2%;
}

.programsearchBtn {
  border-radius: 6px;
  box-shadow: none;
  height: 40px;
}

.card-body-balanceInfo {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #52608e;
}
