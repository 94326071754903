.LOP{
    margin-top: 3em;
    
}
.LOPDataParent{
    height: 65vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.LOPHeader{
    background: #52608E;
    display: flex;
    padding-top: .6em;
    padding-bottom: .6em;
}
.LOPHeaderChild1{
    width: 12.8%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #F2EFEA;

}
.LOPHeaderChild2{
    width: 16%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #F2EFEA;
}
.LOPHeaderChild3{

    width: 11%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1em;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #F2EFEA;

}
.LOPHeaderChild4{
    width: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2em;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #F2EFEA;

}

.LOPHeaderChild5{

    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 1em; */
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #F2EFEA;
}
.LOPHeaderChild6{

    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 1em; */
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #F2EFEA;
}
.LOPHeaderChild7{

    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 1em; */
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #F2EFEA;
}
.LOPHeaderChild8{

    width: 16.2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2em;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #F2EFEA;
}
.LOPDataOdd{
    display: flex;
    background: #FFFFFF;
    min-height: 9em;
}
.LOPDataEven{
    display: flex;
    background: #F3FCFF;
    min-height: 9em;

}
.LOPDataChild1{
    width: 12.8%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
    font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-size: 0.75em;
line-height: 150%;
color: #1D2334;

}
.LOPDataChild2{
    width: 16%;
    display: flex;
    padding-left: 1em;
    flex-direction: column;
    
}
.LOPDataChild2Child{
    display: flex;
    height: 100%;
}
.LOPDataChild2_1{
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 0.6em;
line-height: 150%;
color: #1D2334;
padding-right: .2em;

}
.LOPDataChild2_2{
    width: 25%;
    background: #DEF3FF;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6em;
    line-height: 150%;
    color: #1D2334;
}
.LOPDataChild3{
    width: 11%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 0.7em;
line-height: 150%;
    color: #1D2334;
}
.typeHospital{
   display: flex;
   flex-direction: column;
  align-items: center;
   
}
.LOPDataChild4{
    width: 15%;
    display: flex;
    padding-left: 1em;
    flex-direction: column;

}
.LOPDataChild4Child{
    display: flex;
    height: 100%;
}
.LOPDataChild4_1{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-right: 1em;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 150%;
    color: #1D2334;
}
.LOPDataChild4_2Odd{
    width: 55%;
    background: #F4FFF3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 150%;
    color: #1D2334;
}
.LOPDataChild4_2Even{
    width: 55%;
    background: #FFF8F3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 150%;
    color: #1D2334;
}
.LOPDataChild5{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1em;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 150%;
    color: #1D2334;
}
.LOPDataChild6{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1em;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 150%;
    color: #1D2334;
}
.LOPDataChild7{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1em;
    font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 0.7em;
line-height: 150%;
    color: #319B4B;
}
.LOPDataChild8{
    width: 16.2%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 1em;
    gap: 10%;
    cursor: pointer;
}
.leftHeadLOP{
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftHeadLOP p{
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #1D2334;
margin-top: auto;
margin-bottom: auto;
}
.leftHeadLOPCount{
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 0.8em;
line-height: 150%;
color: #F2EFEA;
background: #E68D36;
border-radius: 6px;
padding: 0.3em 0.7em 0.3em 0.7em;
}
.No_Plans_Found{
    margin-left: auto;
    margin-right: auto;
    margin-top: 5em;
font-family: Rubik;
font-size: 2em;
font-weight: 600;

}