.title{
    background-color: white;
    justify-content: space-around;
    margin-top: 2%;
    padding: 10%;
    min-height: 20%;
}

.titleText{
    font-size: 100px;
}