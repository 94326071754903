.prescriptionFormMain{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
}
.prescriptionFormMainHeading{
    /* background-color: yellow; */
    margin-left: 6.8em;
    margin-right: 9.5em;
}
.prescriptionFormMainHeading p{
    font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1.5em;
line-height: 33px;
color: #73757A;
padding-top: 2.06em;
padding-bottom: 1.93em;
}

.prescriptionFormMainUserInfo{
    background: #FFFFFF;
box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
border-radius: 12px;
    margin-left: 6.8em;
    margin-right: 9.5em;
    padding-left: 1.62em;
}
.prescriptionFormMainUserInfo p{
    font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #1D2334;
margin-top: 0.93em;
}
.prescriptionFormMainStarts{
    background: #FFFFFF;
border-radius: 12px;
    margin-left: 6.8em;
    margin-right: 9.5em;
    margin-top: 2.25em;
    /* background-color: aqua; */
}
.prescriptionFormMainStartsHeading{
    font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 1.68em;
color: #73757A;
padding-left: 1.62em;
padding-top: 0.6em;
}
.labelName{
    font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1em;
line-height: 150%;
color: #1D2334;
padding-left: 1.62em;
padding-bottom: 0.81em;
padding-top: 1.43em;
}
.prescriptionFormMainStarts textarea{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    width: 90%;
    margin-right: 5.9em;
    margin-left: 1.62em;
    /* width: 55em; */
}
.input1{
padding-top: 0.6em;
padding-bottom: 0.6em;
padding-left: 1.56em;
padding-right: 2em;
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #44576A;
overflow-wrap: break-word;

/* min-height: 2.8em; */
}
.input2{
    padding-top: 0.6em;
padding-bottom: 0.6em;
padding-right: 2em;

padding-left: 1.56em;
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #44576A;
overflow-wrap: break-word;

}
.input3{
    padding-top: 0.6em;
padding-bottom: 0.6em;
padding-right: 2em;

padding-left: 1.56em;
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #44576A;
overflow-wrap: break-word;

}
.input4{
    padding-right: 2em;

    padding-top: 0.6em;
padding-bottom: 0.6em;
padding-left: 1.56em;
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #44576A; 
overflow-wrap: break-word;
overflow-y:scroll ;

}
.input5{
padding-right: 2em;

    padding-top: 0.6em;
padding-bottom: 0.6em;
padding-left: 1.56em;
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #44576A; 
overflow-wrap: break-word;
overflow-y:scroll ;
}

.prescriptionFormButton{
    cursor: pointer;
    background: #319B4B;

    border-radius: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25em;
    line-height: 24px;
    color: #FFFFFF;
    border: hidden;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 2.18em;
    padding-right: 2.18em;
    margin-top: 1.8em;
    margin-bottom: 2.56em;
    margin-left: 1.26em;
    margin-left: 82%;
    /* margin-right: auto; */
}
.attributeCardBoxPF{
    /* background-color: rgb(255, 30, 0); */
    width: 90%;
    margin-left: 1.26em;
    border-radius: 12px;

}

.addParameterWrapperPF{
    /* background-color: chartreuse; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}
.inputFieldAddParameterFirstPF{
    width: 100%;
    padding-left:5% ;
    height: 2.5em;
    margin-top: 1.25em;
    border: 1px solid #52608e;
    border-radius: 12px;
    background-color: white;
    color: #1d2334;
    margin-bottom: 0.625em;
    /* margin-left: 3.06em; */
}
.inputFieldAddParameterFirstPFParent{
/* background-color: aqua; */
width: 40%;
margin-left: 3.06em;

}
.addParameterCrossPF{
/* background-color: blue; */
/* width: 10%; */
margin-top: .6em;
margin-left: 1.5em;
outline: none;
}
.addParameterCrossPFNew{
    /* background-color: blue; */
    /* width: 10%; */
    position: absolute;
    top: 14%;
   left: 95%;
    outline: none;
    }
.addParameterCrossButtonPF{
/* background-color: rgb(247, 0, 255); */
background: transparent;
border: hidden;
outline: none;
}
.prevalidation {
    color: red;
    margin-left: 2.5vw;
}