.coupenMain{
    display: flex;
    flex-direction: column;
}
.coupenChild1{
    margin-left: 6.8em;
    margin-right: 4.25em;
}
.coupenChild1 p{
    font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 2em;
line-height: 150%;
color: #1D2334;
margin-top: auto;
margin-bottom: auto;
padding-top: 1.5em;
padding-bottom: 1.5em;
}
.coupenChild2{
    margin-left: 6.8em;
    margin-right: 4.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
border-left: 1px solid #EDE8E8;
border-right: 1px solid #EDE8E8;
border-top: 1px solid #EDE8E8;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
border-bottom: 1px solid #ECEBEB;

}
.coupenChild2 p{
    font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 150%;
color: #1D2334;
margin-top: auto;
margin-bottom: auto;
margin-left: 2.12em;
}
.coupenChild2 button{
    background: #319B4B;
border-radius: 6px;
padding: 0.7em 1.6em 0.7em 1.6em;
margin-top: 1.75em;
margin-bottom: 0.8em;
margin-right: 2.12em;
border: hidden;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1em;
line-height: 22px;
color: #F2EFEA;
}
.coupenChild3{
    margin-left: 6.8em;
    margin-right: 4.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
border-left: 1px solid #EDE8E8;
border-right: 1px solid #EDE8E8;
padding-top: 1em;

}
.coupenChild3_1{
    height: 3.1em;
    width: 20%;
    display: flex;
    align-items: center;
margin-left: 2.12em;

}
.coupenChild3_1_select{
    height: 83%;
    width: 80%;
    background: #FFFFFF;
border: 1px solid #52608E;
border-radius: 6px;
}
.coupenChild3_2{
    display: flex;
    justify-content: center;
    margin-right: 2.1em;
    width: 30%;
    background-color: #319B4B;
    height: 80%;
}
.coupenChild3_2 input{
    width: 100%;
    background: #FFFFFF;
border: 1px solid #52608E;
border-radius: 6px;
font-family: 'Nunito';
font-style: italic;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #52608E;
padding: 0.75em 1em 0.75em 1em;
/* margin-right: 2em; */
margin-top: 1.2em;
margin-bottom: 1.2em;
}
.coupenChild3_2 button{
    background: #52608E;
border: 1px solid #C0C0C0;
border-radius: 6px;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1.1em;
line-height: 150%;
color: #F2EFEA;
padding: 0.7em 1.6em 0.56em 3.56em;
display: flex;
justify-content: space-around;
align-items: center;
margin-top: 1.2em;
margin-bottom: 1.2em;
border: hidden;
}
.coupenChild4{
    margin-left: 6.8em;
    margin-right: 4.25em;
    background: #FFFFFF;
border-left: 1px solid #EDE8E8;
border-right: 1px solid #EDE8E8;
border-bottom: 1px solid #EDE8E8;
border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
margin-bottom: 5em;
}
.CouponDescription{
    width: 40%;
    margin-bottom: 1em;
    background: #FFFFFF;
border: 1px solid #1D2334;
border-radius: 12px;
padding-top: 0.75em;
padding-left: 1.6em;
}
.DatePickerCoupon{
    margin-top: 2em;
    margin-bottom: 2em;
}
.modalCouponButton{
    margin-top: 2em;
}
.seperaterCoupenC{
    display: flex;
    width: 40%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
}
.seperaterCoupenC p{
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
}
.seperaterCoupenC1{
    width: 40%;
    height: .1em;
    background-color: #1D2334;
}
