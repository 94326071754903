.displayBoxHeader {
  margin-left: 4vw;
  margin-top: 5vh;
  font-weight: 800;
}
.displayBoxPara {
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 5vh;
}

.ViewTestParameters {
  min-width: 20vw;
  padding: 1.2vw 1.5vw 1.2vw 1.5vw;
  border: 1px solid #44576a;
  border-radius: 12px;
}


.ParametersIndividualSeperator{
    border-top: 1px solid #44576a;
}