.requestPage {
  margin-left: 5.5625em;
  margin-top: 1.5em;
  margin-right: 5.5625em;
  margin-bottom: 2vw;

}

.requestHead {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  display: flex;
}

.requestBox {
  background-color: white;
}
.requestText {
  margin-left: 3.3125em;
  padding-top: 3em;
}
.requestText div {
  padding-bottom: 4.625em;
}
.requestFile {
  display: flex;
  align-items: center;
}
.requestButton {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 6vw;
}

.requestApprove {
  border: none;
  background: #e68d36;
  border-radius: 12px;
  padding: 0.9375em 10.25em;
  color: white;
  cursor: pointer;
}
.requestDecline {
  border: 1px solid #e68d36;
  border-radius: 12px;
  padding: 0.9375em 10.25em;
  color: #e68d36;
  background: white;
  cursor: pointer;
}

.requestCity {
  display: flex;
  align-items: center;
}

.requestDropdown {
  border: 1.5px solid #52608e;
  border-radius: 6px;
  background-color: white;
  padding: 0.5em 1.6875em;
  margin-left: 1em;
}

select.decorated option:hover {
  box-shadow: 0 0 10px 100px gray inset;
}

.requestPageInfowrapper {
  display: flex;
  padding: 1vw;
  align-items: baseline;
}

.requestPageInfo {
  font-size: 1em;
  font-weight: 700;
  width: 20vw;
}

.requestPageData {
  font-size: 1em;
  /* text-transform: capitalize; */
}

.requestTextHospital2 {
  padding-top: 3vw;
  padding-left: 6vw;
  padding-bottom: 3vw;
}

.approvedTile{
  font-size: 20px;
  background-color: #319B4B;
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 3vw;
}

.approvedTileC{
  font-size: 20px;
  background-color: #b61d01;
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 3vw;
}

.approvedTileP{
  font-size: 20px;
  background-color: #e68d36;
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 3vw;
}
.requestPageInfowrapperClinic{
  margin-top:3vh;
  display: flex;
  padding: 1vw;
  align-items: baseline;
}
