.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-left: 6.875em;
  margin-top: 4.125em;
  margin-right: 2.9793em;
  align-items: baseline;
  /* background-color: #e68d36; */
}

.modalHeader1{
  display: flex;
  justify-content: space-between;
  margin-left: 4em;
  margin-top: 4.125em;
  margin-right: 2.9793em;
  align-items: baseline;
  /* background-color: #e68d36; */
}

.modalHeaderText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  color: #1d2334;
}

.modalh .modalBody {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  grid-gap: 30px;
}

/* .modalInput {
  width: 400px;
  height: 48px;
  border-radius: 12px;
} */
.modalBackground {
  width: 100vw;
  height: 100vh;
  position: left;
  display: flex;
  justify-content: left;
  align-items: left;
  position: fixed;
  /* background-color: aqua */
}

.modalContainer {
  width: 55.62%;
  height: 100vh;
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.closeButtonModal {
  background-color: white;
  border: none;
  outline: none !important;

  font-size: 1.5625em;
  cursor: pointer;
  /* top: 1%; */
  /* position: absolute; */
  /* right: 4%; */
  /* top:5%; */
}
.updatedoctorsignatureBtncheck {
  position: relative;
  top: -5.4vw;
  left: 22vw;
  cursor: pointer;
}
.modalFooterBtn {
  background-color: #e68d36;
  border-radius: 12px;
  width: 400px;
  height: 48px;
}

.errorMessageModalValidation {
  color: rgb(163, 45, 24);

  margin-left: 1%;
  margin-top: -1.875em;
  margin-bottom: 0.4375em;
}
.errorMessageModalValidations {
  color: rgb(163, 45, 24);
  margin-left: 1%;
  margin-top: 0.125em;
  margin-bottom: -0.5625em;
}

.errorMessageModalValidatio {
  color: rgb(163, 45, 24);

  margin-left: 5%;
  margin-left: 3.0625em;
}

.errorMessageModalService {
  color: rgb(163, 45, 24);

  margin-left: 1%;
  /* margin-left: 3.0625em; */
}

.emailExist {
  color: rgb(163, 45, 24);
  margin-left: 1%;
  margin-top: -1.875em;
  margin-bottom: 0.4375em;
}

.emailNotRegistered {
  color: rgb(163, 45, 24);
  margin-left: 1%;
  margin-top: -1.875em;
  margin-bottom: 0.4375em;
}

.inputFieldsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 6.875em;
  /* margin-top: 4.375em; */
  margin-top: 3.5625em;
}

.inputField {
  padding-left: 1.625em;
  width: 40%;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 3vw;
  font-family: "Nunito";
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
}
.inputFieldSignature {
  padding-left: 1.625em;
  width: 40%;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 3vw;
  font-family: "Nunito";
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  padding-top: 0.7vw;
}
/* .inputField::placeholder {
  color: #1d2334;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
} */

/* .inputFieldAddParameterFirst::placeholder {
  color: #1d2334;
} */

.forAutocomplete {
  margin-bottom: 2.6875em;
  font-family: "Nunito";
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;

  border-radius: 12px;
  background-color: white;
  color: #1d2334;
}

.modalButtonAttribute {
  width: 37.75em;
  height: 3em;
  background: #e68d36;
  border-radius: 12px;
  color: white;
  border: none;
  outline: none !important;
  margin-bottom: 5%;
  cursor: pointer;
}

.modalButton {
  width: 25vw;
  height: 2.5em;
  background: #e68d36;
  border-radius: 12px;
  color: white;
  border: none;
  outline: none !important;
  margin-bottom: 5%;
}

.modalButton:hover {
  cursor: pointer;
}

.style1 {
  position: "absolute";
  margin-top: 6.5%;
  /* top: 12.5%; */
  left: 0%;
  width: 48.56em;
  height: 100vh;
  background-color: white;
  border: "2px solid #000";
  box-shadow: 24;
  border-radius: 0px 12px 12px 0px;
  /* overflow-y:auto; */
  overflow-y: scroll;
  /* background-color: blueviolet; */
}
.styledisableModal {
  position: "absolute";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border: "2px solid #000";
  box-shadow: 24;
}
.modalHeaderss {
  font-size: 2.5em;
  font-family: "Nunito";
  margin-bottom: "5.125em";
}

.mainModal {
  overflow-y: auto;
  /* background-color: aqua; */
}

.inputFieldProgram {
  width: 80%;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 5%;
}

.inputFieldDescription {
  width: 30em;
  height: 10.75em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 2.875em;
  padding-left: 4%;
  padding-right: 5%;
  padding-top: 1.5%;
}

/* .inputFieldDescription::placeholder {
  color: #1d2334;
} */

/* .inputFieldDescription::placeholder {
  /* position: absolute;
  top: 10%;
} */
.inputFieldDescription::input {
  padding-top: 0;
  padding-left: 0;
}

.counter {
  border: 1px saddlebrown solid;
}

.Service {
  display: flex;
  flex-direction: row;
}

.datePickerContainer {
  margin-bottom: 5%;
}

.programValue {
  /* margin-left: 5%; */
  width: 9.375em;
  background: #ffffff;
  /* primary_color_1 */
  border: 1px solid #1d2334;
  border-radius: 12px;
  /* display: flex; */
  /* flex-direction: row; */
}

.addService {
  display: flex;
  justify-content: space-around;
  border: none;
  outline: none !important;

  color: #1d2334;
  width: 9.375em;
  background-color: white;
  text-align: center;
  font-weight: 500;
  margin-bottom: 4%;
  /* margin-left: 50%; */
}

.attributesDisplay {
  margin-left: 60%;
}

.servicesContainer {
  display: flex;
  flex-direction: row;
  margin-right: 10%;
}

.attributesContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.optionsAttributes {
  margin-right: 10%;
}

.attributeNameDisplay {
  width: 12.5em;
}

.attributeCardBox {
  border: 1px solid #e6e6e7;
  border-radius: 4px;
  width: 37.75em;
  margin-bottom: 5%;
}

.deleteAttribute {
  background-color: white;
  border: none;
  outline: none !important;
}

.inputFieldAddService {
  width: 24em;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 2.75em;
  padding-left: 5%;
}

/* .inputFieldAddService::placeholder {
  /* padding-left: 6.5%;
} */
.inputFieldAddService::input {
  margin-left: 6.5%;
}

.inputFieldAddAttribute {
  /* margin-top: 2%; */
  width: 25em;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 4.625em;
  margin-left: 3.0625em;
  padding-left: 5%;
}

.inputFieldAddAttributeBox {
  /* margin-top: 2%; */
  width: 25em;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 3.625em;
  margin-left: 3.0625em;
  padding-left: 1.625em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.inputFieldAddAttributeFirst {
  margin-top: 5%;
  width: 25em;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 3.25em;
  margin-left: 3.0625em;
  padding-left: 5%;
}

.inputFieldAddAttributeFirstBox {
  margin-top: 8%;
  width: 25em;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 3.25em;
  margin-left: 3.0625em;
  padding-left: 1.625em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.inputFieldAttributeDescription {
  width: 30em;
  height: 7.5em;
  /* height: 120px; */
  /* padding: 0px 0px;  */
  display: inline-block;
  /* padding:15px 10px; */
  /* line-height:140%; */
  /* padding: 0 0 100px 0; */
  line-height: 30px;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 5%;
  /* margin-left: 8.11%; */
  margin-left: 3.0625em;
  padding-left: 1.625em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.attributeCardCloseButton {
  /* margin-left: 92%; */
  /* padding-top: 10%; */
  margin-left: 32.5em;
  margin-top: 1.5em;
}

.addAttributeButton {
  margin-bottom: 2.8125em;
  background-color: white;
  border: none;
  outline: none !important;

  color: #52608e;
}

.inputFieldDate {
  width: 12.5em;
}

/* .MuiOutlinedInput-root{
  width: 25vw;
} */

.modalButtonAppointment {
  width: 25vw;
  height: 2.5em;
  background: #e68d36;
  border-radius: 12px;
  color: white;
  border: none;
  outline: none !important;

  margin-bottom: 5%;
  margin-top: 3.43em;
  cursor: pointer;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 25vw;
  outline: none;
  color: #fff;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: none;
  /* border: 1px solid black; */
  color: gray;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: grey !important;
  border-color: grey !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
}

.formControl {
  width: 40%;
}

.modalButton {
  margin-top: 2em;
  width: 40%;
}

.modalButtonPackage {
  margin-top: 0.2em;
  width: 40%;
  /* width: 25vw; */
  height: 2.5em;
  background: #e68d36;
  border-radius: 12px;
  color: white;
  border: none;
  outline: none !important;

  margin-bottom: 5%;
}

.modalButtonPackage:hover {
  cursor: pointer;
}

.inputFieldAddParameterFirst {
  margin-top: 1.25em;
  width: 25em;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 0.625em;
  margin-left: 3.0625em;
  padding-left: 5%;
}

.addParameterWrapper {
  display: flex;
}

.addParameterCross {
  margin-top: 5%;
  margin-left: 5%;
}

.addParameterCrossButton {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  outline: none !important;
}

.addParameterButton {
  border: none;
  outline: none !important;

  background-color: #ffffff;
  color: blue;
  margin-left: 3.0625em;
  cursor: pointer;
  margin-bottom: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.textingField {
  padding-left: 1.625em;
  /* width: 40%; */
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 2.6875em;
  font-family: "Nunito";
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
}
/* 
.textingField::placeholder {
  color: #1d2334;
} */

.alignTime {
  display: flex;
  flex-direction: row;
}

.DoctorInHospitalName {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 580;
  font-size: 2em;
  line-height: 150%;
  color: #1d2334;
  margin-left: 3.5em;
}
.updatingtheModal {
  display: flex;
}
.featuringCheck {
  margin-bottom: 2em;
}
.featuringCheck span {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
  margin-left: 1em;
}
.featuringCheckField {
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 4px;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 12px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.inputFieldDate {
  padding-left: 1.625em;
  padding-right: 1.625em;
  width: 40%;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 3vw;
  font-family: "Nunito";
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
}

.DateModalHeader {
  margin-bottom: 0.5vw;
}

.ModalStatement {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 150%;
  color: #1d2334;
  margin-left: 5.575em;
}

.modalInfo {
  display: flex;
  margin-bottom: 2em;
}

.modalInfoLabel{
  width: 10vw;
  margin-right: 2vw;
}

.modalInfoLabels {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.modalInfoLabelsA {
  font-weight: 100;
  cursor: pointer;
  color: blue !important;
}

.modalInfoLabelsA:hover {
  text-decoration: underline !important;
}

.modalInfoValues {
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
}
.seperateTestList {
  position: relative;
}
.testListPackageHeader {
  padding-left: 1em;
  padding-top: 1em;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2em;
  text-transform: capitalize;
  color: #e68d36;
}
.testListPackage {
  height: 30vh;
  width: 45%;
  position: absolute;
  left: 50%;
  bottom: 20%;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.testListPackageList {
  padding-left: 0.8em;
  padding-top: 1em;
  overflow: hidden;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: #1d2334;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.testListPackageList li {
  list-style-position: inside;
  list-style-type: circle;
  padding-left: 1.5em;
  text-indent: -1.5em;
}
.testListPackageList:hover {
  overflow: auto;
}

.inputFieldButtonForm {
  width: 40% !important;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 3vw;
  font-family: "Nunito";
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
}

.inputFieldButtonFormLabel {
  font-family: "Nunito";
}
.DatePickerPharmacy {
  margin-top: 0em;
  margin-bottom: 1.5em;
}
.file-upload {
  width: 40%;
}
.file-upload-select {
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  color: #dbdbdb;
  cursor: pointer;
  text-align: left;
  background: #ffffff;
  border: 1px solid #89a9bb;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  /* margin-bottom: 3.125rem; */
  box-sizing: border-box;
}
.signatureInput {
  height: 48px;
  font-size: 1em;
  background: green;
  border: 1px solid #89a9bb;
  /* border-radius: 12px; */
  /* padding-left: 2.375rem; */
}
.file-select-button {
  color: black;
  font-size: 0.6em;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-upload-select input[type="file"] {
  display: none;
}

.file-select-name3 {
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 0.6em;
}
.FormErrorMesage {
  color: red;
  font-size: 1.2vw;
  color: rgb(163, 45, 24);

  margin-left: 1%;
  margin-top: -1.875em;
  margin-bottom: 0.4375em;
}
.FileSizedisable {
  margin-top: -2vw;
  margin-bottom: 0.4vw;
  margin-left: 0.7vw;
  color: rgb(163, 45, 24);
  font-size: 1.3vw;
}
.errorFile {
  color: rgb(163, 45, 24);
}
.errorFileValidationAddModal {
  color: rgb(163, 45, 24);
  font-size: 1.3vw;
}


.BookingNumberData {
  margin-top: -3vw;
  display: grid;
}
