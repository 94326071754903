.head_doctor_hospitaldash {
  display: flex;
  justify-content: space-between;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1.8125em;
  align-items: center;
}
.Doctor_datestyle_hospdash {
  display: flex;
  margin-left: 22vw;
  background: #ffffff;
  border-radius: 8px;
}
.paymentStartDate_hospdash input {
  margin-left: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 8px;
}
.paymentEndDate_hospdash input {
  margin-left: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 8px;
}
/* view deatils css */
.GridVieWDoctorArrange {
  /* display: flex; */
  margin-top: 1.25em;
  /* margin-right: 10vw; */
  display: grid;
  grid-template-columns: 22vw 1fr;
}
.appointment-details1 {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.SpecialityHeadingViewdoctor {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 150%;
  margin-right: 23vw;
}
.SpecialityContainer {
  display: grid;
  padding-bottom: 6vw;
  grid-template-columns: 28vw 1fr;
}
.SpecialityContainerOneBlock {
  background: #407bff;
  border-radius: 16px;
  color: white;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}
.hospitalviewdoctormain {
  margin-left: 6.875em;
  margin-top: 1.9375em;
  margin-right: 8.75em;
  font-family: "Nuninto", sans-serif;
  padding-bottom: 11vw;
}

.AppointmentStatusvalue {
  margin-left: 2vw;
  width: 28vw;
  padding-top: 0.5vw;
  text-align: center;
  padding-bottom: 0.5vw;
  border: 1px solid #52608e;
  border-radius: 6px;
  display: flex;
  padding-left: 1vw;
}
.AppointmentStatusvalueCross {
  cursor: pointer;
  margin-left: 14vw;
  position: absolute;
  margin-left: 24vw;
}
.AppointmentPaidStatus {
  color: #319b4b;
}
.AppointmentPendingStatus {
  color: #ad343e;
}
.appointment-details-hospitaldash {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
  display: grid;
}
