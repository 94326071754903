.rightHead button{
    background: #319B4B;
    border-radius: 6px;
    padding: 0.7em 1.6em 0.7em 1.6em;
    margin-right: 2.12em;
    border: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 22px;
    color: #F2EFEA;
}