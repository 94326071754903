.ProductListingHeading{
font-family: Rubik;
font-size: 2em;
font-weight: 600;
line-height: 48px;
letter-spacing: 0em;
color: #1D2334;
margin-left: 6.875rem;

}
.ProductListingSearch{
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.ProductListingSearchInput{
   padding: .5em 1em .5em 1em; 
   border: 1px solid #52608E;
   border-radius: 6px;
   margin-right: 1em;
font-family: Rubik;
font-size: 1em;
font-style: italic;
font-weight: 400;
width: 30%;
}
.ProductListingSearchButton{
   padding: .5em 3em .5em 3em; 
background-color: #52608E;
border-radius: 6px;
margin-right: 1em;
color: #fff;
border: hidden;
}.AddProductButton{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.AddProductButton button{
    padding: .5em 3em .5em 3em; 
    background-color: #52608E;
    border-radius: 6px;
    margin-right: 1em;
    color: #fff;
    border: hidden;
}
