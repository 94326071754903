.editCategoryHeader {
  /* margin: 1vw 0vw 1vw 2vw; */
  border-bottom: 1px solid #ede8e8;
  padding-top: 1em;
  padding-left: 2em;
  padding-bottom: 1em;
  border-radius: 12px;
  background-color: white;
  font-size: 32px;
  line-height: 48px;
  border: 1px solid #ede8e8;
  font-weight: 700;
}
.editProductMainBody2 {
  margin-left: 0em;
  margin-right: 54vw;

  border-radius: 12px;
  margin-top: 0em;
  padding-bottom: 5em;
  /* border: 1px solid #EDE8E8; */
  display: flex;
  flex-direction: column;
  /* margin-bottom: 2em; */
}

.editProductMainBody {
  margin-left: 4.2em;
  margin-right: 4.2em;
  border-radius: 12px;
  margin-top: 5em;
  padding-bottom: 5em;
  display: flex;
  flex-direction: column;
}
.editCategoryBody {
  border: 1px solid #ede8e8;
  border-radius: 12px;
  background-color: white;
}

.editCategoryName {
  /* padding-top: 4em; */

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0.2em;
}

.editCategoryField {
  margin-top: 0.5em;
  outline: none;
  width: 90%;
  padding-left: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 12px;
  font-style: italic;
}

.editCategoryFieldSelect {
  width: 90%; /* Set your desired width */
  outline: none;
  padding: 0.5em;
  border-radius: 12px;
  font-style: italic;
  margin-right: 10px; /* Add some spacing between the two fields */
}
/* .editCategoryFieldSelect {
   
    outline: none;
    padding-right: 77.5em;
    padding-left: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 12px;
    font-style: italic;
  } */

.editCategoryDescription {
  padding-top: 2em;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0.2em;
}

.editCategoryFormikBody {
  margin-left: 4em;
}

.editSwitchBody {
  display: flex;
  padding-top: 2em;
  justify-content: space-between;
  margin-right: 70em;
}

.editCategorySwitchName {
  padding-top: 0.3em;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0.2em;
}

.editCategorySwitchName1 {
  padding-top: 0.4em;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0.2em;
}

.editCategorySwitchButton {
  margin-left: 10em;
}

.editCategorySwitchButton1 {
  margin-right: 7vw;
}

.editCategorySwitchButton1 {
  margin-left: 10em;
  margin-bottom: -0.6em;
}
.editSubmitButton {
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  padding-right: 7em;
  padding-left: 7em;
  display: flex;
  margin-top: 3em;
  background-color: #52608e;
  border-radius: 6px;
  color: white;
  outline: none !important;
  border: none !important;
  margin-bottom: 10em;
}

.editSubmitButtons {
  padding-top: 0.7em;
  margin-right: 4em;
  padding-bottom: 0.7em;
  padding-right: 7em;
  padding-left: 7em;
  display: flex;
  margin-top: 3em;
  background-color: #52608e;
  border-radius: 6px;
  color: white;
  outline: none !important;
  border: none !important;
  margin-bottom: 6em;
}
.PriceAndUnits {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.PriceAndUnitsChild {
  width: 30%;
}
.PriceAndUnitsChildField {
  padding: 0.7em;
  border-radius: 6px;
  border: 1px solid #1d2334;
}
.editCategoryName {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.ProductImageBox {
  border: 1px solid black;
  width: 250px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProductImageBoxHeading {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 1em;
  margin-top: 2em;
}
.productImageTilesArea {
  width: 90%;
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
}
.SelectedImageHeading {
  font-family: Rubik;
  font-size: 1em;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}
.productImageTiles {
  width: 20%;
  height: 150px;
  position: relative;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 6px;
}
.CloseButtonProductImage {
  position: absolute;
  right: 7%;
  top: 5%;
}
.Add_Clear_Section {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.Add_Clear_Section button {
  background-color: #52608e;
  border: hidden;
  width: 25%;
  padding: 0.8em;
  font-family: Rubik;
  font-size: 1em;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
  border-radius: 6px;
}
.submitButtonArea {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  margin-top: 1em;
}
.submitButtonArea button {
  background-color: #52608e;
  border: hidden;
  width: 25%;
  padding: 0.8em;
  font-family: Rubik;
  font-size: 1em;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
  border-radius: 6px;
}
.addPriceWrapperParent {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}
.addPriceWrapper {
  display: flex;
  width: 100%;
  margin-top: 2em;
  position: relative;
}
.addPriceWrapperFields {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.closeIconPrice {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addPriceButton {
  margin-top: 1.5em;
  display: flex;
  width: 100%;
}
.editCategoryBodyProduct {
  border: 1px solid #ede8e8;
  border-radius: 12px;
  background-color: white;
}
