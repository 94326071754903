.editCategoryHeader {
  /* margin: 1vw 0vw 1vw 2vw; */
  border-bottom: 1px solid #ede8e8;
  padding-top: 1em;
  padding-left: 2em;
  padding-bottom: 1em;
  border-radius: 12px;
  background-color: white;
  font-size: 32px;
  line-height: 48px;
  border: 1px solid #ede8e8;
  display: flex;
  float: right;
  justify-items: flex-end;
  align-items: flex-end;
}

.editCategoryMainBody {
  margin-left: 4.2em;
  margin-right: 4.2em;
  margin-top: 1em;
  border-radius: 12px;
  padding-bottom: 5em;
  /* border: 1px solid #EDE8E8; */
  display: flex;
  flex-direction: column;
  /* margin-bottom: 2em; */
}
.editCategoryBody {
  border: 1px solid #ede8e8;
  border-radius: 12px;
  background-color: white;
}

.editCategoryName {
  /* padding-left: 4em; */
  padding-top: 4em;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0.2em;
}
.editCategoryField1 {
  margin-top: 0.5em;
  outline: none;
  padding-left: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 12px;
  min-height: 150px; 
  width: 90%;
  font-style: italic;

}

.editCategoryField {
  margin-top: 0.5em;
  outline: none;
  /* padding-right: 70em; */
  padding-left: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 12px;
  font-style: italic;
  /* outline: none !important; */
  /* border: none  !important; */
}

.editCategoryFieldSelect {
  width: 1350px; /* Set your desired width */
  outline: none;
  padding: 0.5em;
  border-radius: 12px;
  font-style: italic;
  margin-right: 10px; /* Add some spacing between the two fields */
}
.editCategoryDescription {
  padding-top: 2em;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0.2em;
}

.editCategoryFormikBody {
  margin-left: 4em;
}

.editSwitchBody1{
  margin-top: 3vw;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.editSwitchBody2 {
  display: flex;
  padding-top: 2em;
  justify-content: space-between;
  margin-right: 27em;
}

.buttonCreate{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.editCategorySwitchName {
  padding-top: 0.3em;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0.2em;
}

.editCategorySwitchName1 {
  padding-top: 0.4em;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0.2em;
}

.editCategorySwitchButton {
  margin-left: 10em;
}

.editCategorySwitchButton1 {
  margin-left: 10em;
  margin-bottom: -0.6em;
}
.editSubmitButton1 {
  padding-top: 0.6em;
  padding-bottom:0.6em;
  padding-right: 10em;
  padding-left: 10em;
  display: flex;
  margin-top: 3em;
  background-color: #52608e;
  border-radius: 6px;
  color: white;
  outline: none !important;
  border: none !important;
  margin-bottom: 10em;
}

.categoryMainHeader {
  margin-left: 2.2em;
  margin-right: 4.2em;

  margin-top: 2em;
font-weight: bold;
  font-size: 32px;
  display: flex;
  flex-direction: column;
}
.categoryListBody{
 
  padding-right: 1em;
  padding-left: 1em;
  /* display: flex; */
}

.categoryListBody1{
  display: flex;
  width: 100%;

}

.editCategoryBox{
  border-bottom: 1px solid #ede8e8;
  padding-top: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
display: flex;

  padding-bottom: 0.5em;

  border-radius: 12px;
  background-color: white;
  font-size: 16px;
  line-height: 48px;
  border: 1px solid #ede8e8;

}
.editSubCategoryBox{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.categoryDropDown{
  display: flex;
  
}
.categoryOrderName{
  padding-right:2em;

}
.categoryDropDownBox{
  margin-top: -0.5em;
}

.categoryCheckBox1{
  padding-top: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
}