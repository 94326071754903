.booked {
    background-color: white;
    margin-top: 2%;
    padding: 1%;
    padding-right: 20px;
    border-radius: 4px;
    white-space: nowrap;
}

.dashboardbox{
    padding-left: 34px;
    padding-right: 34px;
}

.number {
    color: #E68D36;
}