.reasonArea{
    margin-left: 7.6vw;
    margin-right: 19.4vw;
    display: flex;
    flex-direction: column;
    align-items:center;
   height: 25vh;
   /* box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1); */
   border-radius: 12px;
   position: relative;
   /* padding:1vw; */
}
.reasonTextArea{
    width: 100%;
   border-radius: 12px;
padding: 1em  1em 1em 1.5em;

}
.reasonArea button{ 
    margin-top: 1em;
    position: absolute;
    right: 0;
    bottom: 1em;
}