/* .btn {
  position: absolute;
  right: 7%;
} */

.btn2 {
    /* position: absolute; */
    /* right:18%;  */
    margin-right: 2%;
    text-align: center;
    margin-bottom: 1%;
    background-color: #319b4b;
  }
  
  .appointmentsearchBtn {
    border-radius: 6px;
    box-shadow: none;
    height: 40px;
  }
  
  .appleftHead1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    width: 35em;
  }
  