.LOCMain {
  margin-left: 6.8em;
  margin-right: 9.5em;
  display: flex;
  flex-direction: column;
}
.LOCHeader {
  display: flex;
  justify-content: space-between;
}
.LOCHeaderChild {
  width: 30%;
  height: 7.2em;
  margin-top: 3em;
  background: #ffffff;
  border-radius: 4px;
}
.LOCHeaderChild :nth-child(1) {
  padding-top: 1.5em;
  padding-left: 1.5em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}
.LOCHeaderChild :nth-child(2) {
  margin-top: 0.5em;
  padding-left: 0.7em;
  padding-bottom: 0.7em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  color: #e68d36;
}
.LOCTableHeader {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 150%;
  color: #36384c;
  padding-left: 1.5em;
  padding-top: 1.2em;
}
.MainTablePagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.MainTablePagination p {
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-right: 1em;
}
.searchSectionTalk {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-bottom: 1em;
}
