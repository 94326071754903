/* styles.css */
.switchbuttonNew {
  width: 32vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6vw;
  height: 3.4vw;
  color: white;
  cursor: pointer;
  gap: 2%;
  background: #ad343e;
  border: 1px solid #a93c3c;
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
}

.modal-overlay {
  z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the last value (0.5) for opacity */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .switchUserHeading{
    display: flex;
  align-items: center;
  justify-content: center;
margin-bottom: 2vw;
font-size: 1.6vw;

  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    max-height: 80vh;
    overflow: auto;
  }
  
  .loginScreenMainHeader1{
    display: flex;
    align-items: center;
    align-content: center;
  }

  .closeSwithchModal{
    display: flex;
    margin-left: 3vw;
  }
  .closeModalUserSwitch{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .SelectRoleDropdown1 {
    margin-top: 1em;
    margin-bottom: 3em;
    padding: 1em 5em 1em 2em;
  }