.insurance-main {
  margin-left: 6.875em;
  margin-top: 2.125em;
  margin-right: 8.75em;
  font-family: "Nuninto", sans-serif;
  margin-bottom: 10%;
}

.insurance-id {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  margin-bottom: 1em;
}

.box {
  background-color: white;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}

.insurance-box-head {
  margin-top: 2.5em;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2.5em;
  margin-right: 2em;
  margin-left: 2em;
  justify-content: space-between;
}

.name {
  margin-right: 3%;
}

.packageDescription {
  font-size: 15px;
  font-family: "Nunito";
  font-style: normal;
}

.status {
  margin-top: -0.5%;
  background-color: #e68d36;
  margin-right: 3%;
  padding: 5px;
  border-radius: 6px;
}

.edit {
  color: blue;
  margin-right: 3%;
}

.desc {
  margin-top: 2%;
  margin-right: 25%;
  font-weight: 400;
}

.desc-head{
  font-weight: bold;
  font-family: "Nunito";
  font-style: normal;
}

.detailss {
  display: flex;
  flex-direction: column;
  margin-top: 2.0625em;
  display: flex;
  margin-left: 2em;
  font-size: 20px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  position: relative;
  /* background-color: aqua; */
}
.detailssChild{
  display: flex;
  width: 30%;
  justify-content: space-between;
  /* background-color: #319b4b; */
}
.insurance-value {
  margin-left: 5.5em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.keyss {
  font-weight: bold;
  margin-bottom: 4.5%;
}

.time {
  width: 200%;
}

.buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 4.375em;
  margin-bottom: 4.0625em;
}

.btnAssign {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px;
  background: #319b4b;
}

.btnDeletes {
  align-items: center;
  padding-left: 9em;
  padding-right: 9em;
  padding-top: 0.625em;
  padding-bottom: 0.6875em;
  border-radius: 12px;
  background: #a53f2b;
  color: white;
  cursor: pointer;
}

.editBtn {
  border: none;
  outline: none !important;

  background-color: white;
  color: blue;
  cursor: pointer;
}

.about {
  margin-top: 2.43em;
  margin-left: 2.12em;
  width: 7.25em;
  height: 1.5em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}

.Lab-editable {
  display: flex;
  color: blue;
  margin-left: 90em;
}


.PackagingTests {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.75em;
  line-height: 150%;
  margin-left: 1em;

  /* text-transform: capitalize; */
  /* margin-left: 5.5em; */

  color: #44576a;
}

.boxxing {
  display: flex;
  justify-content: space-between;
  width: 65vw;
  height: 4.81em;
  background: #38466c;
  border-radius: 12px;
  margin-left: 1em;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;

  /* identical to box height, or 21px */

  color: #ffffff;
}

.arrower {
  display: flex;
  justify-content: flex-end;
}


.package-card-head img{
  position: absolute;
  right: 2%;
  top: 25%;
}
.package-card-head {
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1em;
  width: 50%;
  margin-right: 5%;
  margin-top: 5%;
  background-color: #38466C;
border-radius: 12px;
padding-left: 2em;
padding-top: .7em;
padding-bottom: .5em; 
font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 150%;
   color: #FFFFFF; 
/* background-color: #319b4b; */
}
.package-card-headnew{
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
  width: 50%;
  margin-right: 5%;
  margin-top: 5%;
background-color: #ffffff;
  border: 1px solid #38466C;
  border-radius: 12px;
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em; 
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #38466C;
}

.Collapsiblen1{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 150%;
   color: #FFFFFF; 
   padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em; 
}
.Collapsiblen2{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 150%;
   color: #FFFFFF; 
   padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em; 
  
}


.dropdownImage{
  margin-top: .5em;
  /* margin-right: 1.9em; */
  position: absolute;
  left: 90%;
  bottom: 75%;
  cursor: pointer;
}

.collapseP{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 150%;
  color: #6F6F6F;
margin-top: 2em;
margin-bottom: 1em;

}


.viewPackageBottom {
  margin-left: 2.125em;
  margin-top: 2.3125em;
  margin-bottom: 2.3125em;
}
.viewPackagePreTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}

.viewPackagePreDesc {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}

.viewPackageInter {
  margin-top: 2.3125em;
}

.viewPackageInterTitle {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}

.viewPackageInterDesc {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}
.cardbox{
  /* background-color: #319b4b; */
  display: flex;
  flex-direction: column;
}
.featureDIV{
  margin-top:2em;
    padding-left:1em;
    position:absolute;
    right:5em;
    top:-2.5em;
   
}
