.servicename {
  margin-top: 2.06em;
  margin-left: 6.875rem;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
}

.servicemainBox {
  margin-top: 1.0625em;
  margin-left: 6.875em;
  margin-right: 8.75em;
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
}

.servicehead {
  margin-top: 2.5em;
  margin-left: 2em;
  margin-right: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
}

.serviceLeftHead {
  display: flex;
  justify-content: space-between;
}

.servicelist {
  /* margin-left: 2.125em;
  margin-top: 2.5em; */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

.servicecount {
  background-color: #e68d36;
  padding-left: 1.1875em;
  padding-right: 1.1875em;
  padding-top: 0.1875em;
  padding-bottom: 0.0625em;
  border-radius: 0.375em;
}

.service-addbtn {
  /* margin-left: 39.75em;
  margin-top: 2.5em; */
  border: none;
  outline: none !important;

  padding: 6px;
  /* margin-right: 2em; */
  text-align: center;
  /* margin-bottom: 1%; */
  background-color: #319b4b;
  color: white;
}
/* 
.list {
  width: 20%;
} */

.modify {
  display: flex;
}

.box-body {
  display: flex;
  width: 100%;
}

.cards {
  margin-top: 5%;
  background-color: white;
  margin-left: 7%;
  border: 1px solid white;
  border-left: 10px solid #52608e;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  min-width: 25%;
  max-width: 150%;
}

.card-head {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  margin-left: 15%;
  font-size: 16px;
  max-width: 100%;
}

.card-edit {
  cursor: pointer;
}

.card-text {
  margin-top: 2%;
}

.service-custom-card {
  margin-top: 5%;
  background-color: white;
  margin-left: 7%;
  border: 1px solid white;
  border-left: 10px solid #319b4b;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  min-width: 25%;
  max-width: 150%;
}

.custom-card-head {
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  margin-left: 15%;
  width: max-content;
  font-size: 16px;
  color: #319b4b;
}

.card-body {
  margin-left: 9%;
  color: #1d2334;
  font-size: 14px;
}

.card-edit {
  margin-left: 50%;
}

.card-text {
  margin-top: 2%;
}

.all {
  width: 4.68rem;
  height: 3rem;
  margin-left: 3.12rem;
  margin-top: 0.80rem;
  margin-bottom: 540;
  background: #52608E;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #F2EFEA;
  border: 1px solid #52608E;
border-radius: 6px;
}

.subscribe {
  width: 8.47rem;
  height: 3rem;
  margin-left: 2.25rem;
  margin-top: 1.18rem;
  background: #FFFFFF;
  font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 1rem;
color: #E68D36;


border: 1px solid #E68D36;
border-radius: 6px;
}
.custom{
  width: 6.81rem;
height: 3rem;
margin-left: 3.25rem;
margin-top: 1.18rem;
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 150%;
color: #319B4B;
background: #FFFFFF;
border: 1px solid #319B4B;
border-radius: 6px;

}
.checkup{
    width: 7.75rem;
height: 1.68rem;
margin-left: 47.37rem;
margin-top: -0.44rem;
font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1.12rem;
line-height: 150%;
color: #1D2334;
}
/* .card{
    width: 12.37rem;
    height: 13.75rem;
    box-sizing: border-box;
    background: #FFFFFF;
border: 1px solid #EDE8E8;
border-radius: 0.75rem;
margin-left: 71.37rem;
margin-top: 23.56rem;
} */