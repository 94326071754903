.h4 {
  margin-left: 6%;
  margin-top: 2%;
  font-family: "Nunito", sans-serif;
}

.mainBox {
  margin-top: 1.625em;
  margin-left: 6.875rem;
  margin-right: 5.875em;
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
  /* width: 89%; */
}

.head {
  display: flex;
  /* padding-left: 5%; */
  padding-right: 5%;
  /* padding-top: 2%; */
}

.doctorcount {
  background-color: #e68d36;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  padding-bottom: 1px;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 2%;
}

.btn {
  /* margin-left: 60%; */
  text-align: center;
  margin-bottom: 1%;
  background-color: #319b4b;
  color: white;
}

/* .list {
  width: 20%;
} */

.search {
  display: flex;
  margin-bottom: 3%;
  margin-top: 2%;
}

.searchBox {
  margin-left: 10%;
}

.autocomplete {
  width: 20%;
}

.modify {
  display: flex;
}

.doctorsearchBtn {
  border-radius: 6px;
  box-shadow: none;
  height: 40px;
  cursor: pointer;
}

.MuiTablePagination-toolbar {
  display: flex;
  -webkit-box-align: center;
  align-items: baseline;
}

.MuiTablePagination-displayedRows {
  display: none;
}

.MuiTablePagination-actions {
  display: none;
}

.pagination {
  display: flex;
  margin-right: 5vw;
  align-items: baseline;
  justify-content: flex-end;
  margin-top: 2vh;
}

.dropDown {
  padding: 5px;
  border: 12px;
  margin-right: 3vw;
}
