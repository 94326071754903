.hosp-h4 {
  margin-left: 2.125em;
  margin-top: 2%;
  font-family: "Nunito", sans-serif;
}

.cities {
  padding-left: 20px;
  padding-right: 20px;
}

.mainBox {
  margin-top: 1.625em;
  margin-left: 6.875rem;
  margin-right: 5.875em;
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
  /* width: 89%; */
}

.rightheadbtn {
  width: 100%;
 
  display: flex;

  align-items: flex-end;
  justify-content: flex-end;
}

.rightheadbtn2{
  width: 100%;
  padding-left: 1vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.9em;
  padding-right: 1em;
}
.heads {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
}

.hospitalcount {
  background-color: #e68d36;
  padding-left: 1.75em;
  padding-right: 1.75em;
  padding-top: 0.1875em;
  padding-bottom: 0.0625em;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 2%;
}

.btn {
  text-align: center;
  margin-bottom: 1%;
  background-color: #319b4b;
  color: white;
  font-family: "Nunito", sans-serif;
}

.list {
  /* width: 20%; */
  font-family: "Nunito", sans-serif;
}

.search {
  display: flex;
  margin-bottom: 3%;
  margin-top: 2%;
  width: 100px;
  font-family: "Nunito", sans-serif;
}

.searchBox {
  margin-left: 10%;
}

.autocomplete {
  width: 20%;
}

.modify {
  display: flex;
}

.location {
  width: 100px;
}

.row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1%;
  margin-right: 2%;
  flex-wrap: nowrap;
}

.searchSection {
  display: flex;
  gap: 1.5625em;
}

.hospitalsearchBtn {
  border-radius: 6px;
  box-shadow: none;
  height: 2.5em;
  cursor: pointer;
}

.leftHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 22em;
}
