.header {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10%;
}

.closeButton {
  background-color: white;
  border: none;
  outline: none !important;

  cursor: pointer;
}

.fileInput {
  margin-bottom: 2%;
  cursor: pointer;
}

.headingFile {
  display: flex;
}

.display-linebreak {
  white-space: pre-line;
}

.fileFormatButton{
border: none;
outline: none !important;

background-color: white;
color: blue;
cursor: pointer;
}


.uploadButton{
padding-left: 2%;
padding-right: 2%;
border-radius: 5px;
margin-top: 5%;
border: 1px solid black;
}


.buttonWrapper{
  display: flex;
  justify-content: space-between;
  margin-top: 10%;
}
