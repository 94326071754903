.main {
    margin-left: 5%;
    margin-top: 5%;
    font-family: "Nuninto", sans-serif;
    margin-bottom: 10%;
  }
  
  .corporate-box {
    background-color: white;
    border: 1px solid #ede8e8;
    border-radius: 12px;
  }
  
  .corporate-box-head {
    margin-top: 3%;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 2%;
  }
  
  .corporate-name {
    margin-right: 3%;
  }
  
  .corporate-status {
    margin-top: -0.5%;
    background-color: #e68d36;
    margin-right: 3%;
    padding: 5px;
    border-radius: 6px;
  }
  
  .corporate-desc {
    margin-top: 2%;
    margin-right: 25%;
    font-weight: 400;
  }
  
  .corporate-details {
    display: flex;
    margin-top: 5%;
    margin-left: 5%;
  }
  
  .corporate-keys {
    font-weight: bold;
  }
  
  .corporate-value{
    margin-left: 10%;
  }
  
  .time {
    width: 200%;
  }
  
  .corporate-buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 7%;
    margin-bottom: 5%;
  }
  
  .corporate-btnAssign {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
    background: #319b4b;
    border: 1px solid #319b4b;
  }
  
  .corporate-btnAssign:hover{
      cursor: pointer;
  }
  
  .corporate-btnCancel {
    margin-left: 10%;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
    background: #e68d36;
    color: white;
    border: 1px solid #e68d36;
  }
  
  .corporate-btnCancel:hover{
      cursor: pointer;
  }
  