.btn2 {
  margin-right: 2%;
  text-align: center;
  margin-bottom: 1%;
  background-color: #319b4b;
}

.appointmentsearchBtn {
  border-radius: 6px;
  box-shadow: none;
  height: 40px;
}

.appleftHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.NewDroppdownn {
  background: #FFFFFF;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  border: 1px solid #52608E;
  border-radius: 6px;
}