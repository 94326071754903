.addBannerModal{
    position: absolute;
    width: 50%;
    min-height: 70vh;
    background-color: white;
    top: 15%;
    left: 0 ;
    right: 0 ;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
border-radius: 12px;
border: 2px solid #CEC9C9;
backdrop-filter: blur(2px);
}
.addBannerModalCross{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.addBannerModalChild{
    display: flex;
width: 95%;
}
.addBannerModalChildLeft{
    display: flex;
    width: 45%;
    align-items: center;
color: var(--primary-color-11, #52608E);
font-family: Rubik;
font-size: 1em;
font-style: normal;
font-weight: 500;
line-height: 150%;
}
.addBannerModalChildRight{
    display: flex;
    width: 55%;
    align-items: center;
color: var(--primary-color-11, #52608E);
font-family: Rubik;
font-size: 1em;
font-style: normal;
font-weight: 500;
line-height: 150%; 
padding: 0.6em 0.6em 0.6em 1em;

}
.addBannerModalChildRight select{
    width: 90%;
    color: var(--primary-color-11, #52608E);
font-family: Rubik;
font-size: 1em;
font-style: normal;
font-weight: 500;
line-height: 150%; 
padding: 0.6em 0.6em 0.6em 1em;

}
.addBannerModalChildRight input{
    width: 90%;
    color: var(--primary-color-11, #52608E);
font-family: Rubik;
font-size: 1em;
font-style: normal;
font-weight: 500;
line-height: 150%; 
padding: 0.6em 0.6em 0.6em 1em;
}
.addBannerButton{
    color: var(--secondary-color-3, #F2EFEA);
font-family: Nunito;
font-size: 1em;
font-style: normal;
font-weight: 700;
line-height: normal;
border-radius: 6px;
background: var(--primary-color-3, #319B4B);
border: hidden;
padding: 0.6em 1em;
margin-top: 1.5em;
}
.addBannerModalChildLeftHeading{
    display: flex;
    width: 65%;
    align-items: center;
color: var(--primary-color-11, #52608E);
font-family: Rubik;
font-size: 2em;
font-style: normal;
font-weight: 500;
line-height: 150%;

}

.CreateCardNumberDownloadButton{
    display: flex;
    cursor: pointer;
    margin-left: 58vw;
    color: #52608E;
}

.addCardNumerModalChildRight input {
    width: 22vw;
    color: var(--primary-color-11, #52608E);
    font-family: Rubik;
    font-size: 1em;
    font-style: normal;
    border-radius: 7px;
    font-weight: 500;
    line-height: 150%;
    border: 1px solid grey;
    padding: 0.6em 0.6em 0.6em 1em;
}