.appointmentmain {
    margin-left: 6.875em;
    margin-top: 1.9375em;
    margin-right: 8.75em;
    font-family: "Nuninto", sans-serif;
  }
  
  .appId {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
  }
  
  .appointmentbox-head {
    margin-top: 2.5em;
    margin-left: 2.125em;
    margin-bottom: 2.6875em;
    display: flex;
  }
  
  .name {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }
  
  .statusing {
    
    
    
    
    
    /* color: #f2efea; */
  }
  
  .appointment-box {
    background-color: white;
    border: 1px solid #ede8e8;
    border-radius: 12px;
  }
  
  .appointment-box-head {
    margin-top: 3%;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 2%;
  }
  
  .appointment-name {
    margin-right: 3%;
  }
  
  .appointment-box-body {
    margin-left: 2.125em;
  }
  
  .app-desc {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }
  
  .desc {
    width: 90%;
    margin-top: 1.75em;
    /* margin-right: 34.125em; */
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  
  .appointment-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .appoi {
    display: flex;
    margin-top: 2.75em;
  }
  
  .appointment-keys {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }
  
  .appointment-value {
    margin-left: 5.5em;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  
  .inputFieldOtp {
    padding-left: 25px;
    width: 25vw;
    height: 40px;
    border: 1px solid #52608e;
    border-radius: 12px;
    background-color: white;
    color: #1d2334;
    margin-bottom: 15px;
    font-family: "Nunito";
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  
  .otpForm {
    margin-top: 5.56em;
    border: 1px solid #646161;
    border-radius: 12px 0px 0px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    padding-left: 2.1875em;
    padding-right: 1.625em;
  }
  
  .otphead {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
  }
  
  .otpfoot {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
  }
  
  .startBtn {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
  }
  
  .appointment-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 3.375em;
    margin-bottom: 5%;
    margin-left: 6.625em;
    margin-right: 5.75em;
  }
  
  .appointment-btnAssign {
    padding-left: 5.9375em;
    padding-right: 5.9375em;
    padding-top: 0.75em;
    padding-bottom: 0.5625em;
    border-radius: 12px;
    background: #319b4b;
    border: none;
    outline: none !important;
    cursor: pointer;
    color: white;
  }
  
  .appointment-btnCancel {
    padding-left: 5.9375em;
    padding-right: 5.9375em;
    padding-top: 0.75em;
    padding-bottom: 0.5625em;
    padding-bottom: 10px;
    border-radius: 12px;
    background: #e68d36;
    color: white;
    border: none;
    outline: none !important;
    cursor: pointer;
  }
  
  .appointment-btnDelete {
    padding-left: 5.9375em;
    padding-right: 5.9375em;
    padding-top: 0.75em;
    padding-bottom: 0.5625em;
    padding-bottom: 10px;
    border-radius: 12px;
    background: #a53f2b;
    cursor: pointer;
    color: white;
    border: none;
    outline: none !important;
  }
  
  .otpInput {
    width: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .timeSlot {
    display: flex;
  }
  
  .startBtn {
    background: #52608e;
    border-radius: 12px;
    width: 320px;
    height: 48px;
    left: 1010px;
    top: 736px;
    color: white;
  }
  
  .dates_time {
    margin-top: -16px !important;
  }
  .BookingFormLab{
    width: 21vw;
    height: 3vw;
    border: 1px solid #1D2334;
    background-color: #ffffff;
    padding-left: 1vw;
  }



  .bookingsGrid{
    margin-top: 2vw;
    display: grid;
    grid-template-columns: 1fr 3fr  ;
    /* padding: 1vw; */
    grid-gap: 3vw;

  }

  .b1{
    grid-template-columns: 1/3;
    background-color: aqua;
  }
  .b2{
    background-color: rgb(35, 59, 59);
  }
  .b3{
    background-color: rgb(194, 223, 223);
  }
  .b4{
    background-color: rgb(72, 255, 0);
  }