.viewAccountsPageInfowrapper {
  display: flex;
  padding: 1.2vw;
}

.infoViewOrderDetails {
  display: inline-block;
  margin-top: 0vw;
  position: absolute;
  cursor: pointer;
}


.ViewAccountsStatus_Successful {
  font-size: 1.4vw;
  background: #b1e7bf;
  border-radius: 6px;
  color: #319b4b;
  padding: 0.3em 2em 0.3em 2em;
  height: fit-content;
}

.ViewAccountsStatus_Unsuccessful {
  font-size: 1em;
  background: #f6d6b6;
  border-radius: 6px;
  color: #a53f2b;
  padding: 0.3em 2em 0.3em 2em;
  height: fit-content;
}

.ViewAccountsStatusChangeDropdown {
  background: #f8f8f8;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px 12px 12px 12px;
  padding: 0.3em 2em 0.3em 2em;
width: 50%;
}



.ViewAccountsStatusChangeButton {
  border: none;
  background: #319B4B;
  border-radius: 12px;
  padding: 0.9375em 10.25em;
  color: white;
  cursor: pointer;
  background-color: #b1e7bf;
  width:100%
}
.statusChangeButton{
  border: none;
  background: #319B4B;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  padding: 0.6em 1em 0.6em 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 40%;
  height: 3em;
}
.ViewAccountsStatusChangeWrapper{
  display: flex;
  margin: 0 auto;
  width: 85%;
  margin-top: 1em;
  margin-bottom: 1em;
}


.ViewAccountsStatusChangeButtonForChange{
  border: none;
  background: #319B4B;
  border-radius: 12px;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  padding-left: 3em;
  padding-right: 3em;
  color: white;
  cursor: pointer;
}
.orderHistoryTiles{
  cursor: pointer;
}
.orderHistoryTilesArea{
  width: 85%;
position: relative;
  margin: 0 auto;
}
.orderHistoryTilesAreaChild{
  display: flex;
  flex-direction: column;
}
.orderHistoryTiles{
  width: 100%;
  margin-left: 1em;
  margin-top: 1em;
  border: 1px solid ;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 2em;

}
.orderHistoryTilesAreaHeading{
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  padding-left: .5em;
}
.orderHistoryTilesRows{
  display: flex;
  padding-top: .5em;
  padding-bottom: .5em;
}
.orderHistoryTilesLeft{
  width: 50%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  padding-left: 1em;
}
.orderHistoryTilesRight{
  width: 50%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  padding-left: 1em;

}
.ViewAccountsStatusChangeButtonForChangeParent{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 5em;
  right: 2%;
}
.orderHistoryTilesRightDeliveryStatus{
  background-color:#F6D6B6 ;
  padding: 0.3em 1em 0.3em 1em;
  border-radius: 6px;
  color: #AC6471;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
}

.initiateRefundIndividual{
  border: none;
  background: #319B4B;
  border-radius: 12px;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  padding-left: 5em;
  padding-right: 5em;
  color: white;
  cursor: pointer;
  position: absolute;
  right: -1em;
  top: -1em;
}
.initiateRefundIndividualSelect{
  border: 1px solid black;
  border-radius: 12px;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  padding-left: 1em;
  padding-right: 5em;
  color: black;
  cursor: pointer;
  background-color: white;
  width: 90%;
}
.requestBox{
  position: relative;
}
.orderHistoryTiles.selected {
  box-shadow: 0 0 10px 4px orange;
  cursor: pointer;
  border: orange;
}
.ModalOrderRemarks{
  background-color: white;
  width: 30vw;
  min-height: 30vh;
  border: 1px solid black;
  position: fixed;
  top: 10%;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
}
.ViewAccountsStatusChangeDescription {
  background: #f8f8f8;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 0.3em 1em 0.3em 1em;
  height: 50%;
  width: 90%;
  margin-top: 1em;
}
.ViewAccountsStatusChangebutton{
 display: flex;
 align-items: flex-end;
 justify-content: center;
 height: 30%;
 width: 100%;
 padding-bottom: 1em;
 padding-top: 1em;
}
.ViewAccountsStatusChangebutton button{
  border: none;
  background: #319B4B;
  border-radius: 12px;
  padding:1em;
  color: white;
  cursor: pointer;
width: 30%;
}
.ViewAccountsStatusChangeCloseDiv{
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}
.changeStatusButton{
  border: none;
  border-radius: 12px;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  padding-left: 2em;
  padding-right: 2em;
  color: white;
  cursor: pointer;
  position: absolute;
  background: #319B4B;
right: 0;
top: -1em;
text-align: center;
}
.modalButtonRefund
{
  width: 40%;
  background: #e68d36;
  border-radius: 12px;
  color: white;
  border: none;
  outline: none !important;
  margin-bottom: 5%;
  height: 3.1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statusAlert{
  display: flex;
  align-items: center;
  justify-content: center;
}