.delete:hover {
  cursor: pointer;
}

.edit:hover {
  cursor: pointer;
}

.searchBtn:hover {
  background-color: #52608e;
  color: white;
  cursor: pointer;
}

.searchBtn {
  border-radius: 6px;
  box-shadow: none;
}

.boxx {
  display: flex;
}

.h4 {
  margin-left: 6%;
  margin-top: 2%;
  font-family: "Nunito", sans-serif;
}

.mainBox {
  margin-top: 1.625em;
  margin-left: 6.875rem;
  margin-right: 5.875em;
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
  /* width: 89%; */
}

.employee-head {
  display: flex;
  padding-left: 2.125em;
  padding-right: 2em;
  padding-top: 1.8125em;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.employee-leftHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 20em;
  flex-wrap: wrap;
}

.employeecount {
  background-color: #e68d36;
  padding-left: 0.75em;
  padding-right: 0.625em;
  padding-top: 0.1875em;
  padding-bottom: 0.0625em;
  border-radius: 6px;
  text-align: center;
}

.addButtons {
  display: flex;
  justify-content: space-between;
}

.btns {
  background-color: #319b4b;
  margin-left: 1em;
  border: none;
  outline: none !important;

  color: white;
  border-radius: 6px;
  padding: 2%;
  cursor: pointer;
}

.row2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  /* background-color: #319b4b; */
}

.searchSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* .list {
  width: 20%;
} */

.search {
  display: flex;
  margin-bottom: 3%;
  margin-top: 2%;
}

.searchBox {
  margin-left: 10%;
}

.autocomplete {
  width: 20%;
}

.modify {
  display: flex;
}

.employeesearchBtn {
  border-radius: 6px;
  box-shadow: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9%;
}

.employeesearchBtn p {
  margin-top: auto;
  margin-bottom: auto;
  /* padding-right: 6%; */
}

.NoProgram {
  margin-left: 5%;
  font-family: "Rubik";
  font-style: normal;
  font-size: 14px;
}

.row3 {
  display: flex;
  justify-content: space-between;
  margin-left: 50vw;
  margin-right: 2vw;
  text-align: center;
  align-items: center;
}
.EmployeeFilter{
  cursor: pointer;
}
.mainBoxEmp {
  margin-top: 14.625em;
  /* margin-left: 6.875rem; */
  /* margin-right: 5.875em; */
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
  /* width: 86.5%; */
}