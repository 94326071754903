.DashBoard_grid-container {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* padding: 1vw; */
  justify-content: space-between;
}
.DashBoard_grid-container1 {
  margin-top: 2vw;
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* padding: 1vw; */
  justify-content: space-between;
}
.Dashboard_grid-item {
  background: #ffffff;
  border-radius: 4px;
  width: 23.3vw;
  padding: 1vw;
  /* height: 9vw; */
}
.Dashboard_InnerContainer {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 30px;
}
.Dashboard_OuterContainer {
  background: #e7eaed;
  margin-top: -2.1vw;
}
.Dashboard_SecondText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  margin-top: 1vw;
  font-size: 25px;
  line-height: 150%;
  color: #e68d36;
  margin-left: 24px;
}
.Dashboard_FirstText {
  font-size: 16px;
  margin-left: 24px;
  /* margin-top: 24px; */
}
.DashBoard_HRA_grid-container {
  /* display: grid; */
  /* grid-template-columns: 1fr ; */
  padding: 1vw;
  grid-gap: 19vw;
}
.HealthRiskOverview {
  background: #ffffff;
  border-radius: 4px;
  margin-top: 40px;
}
.Dashboard_BoldHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #1d2334;
}
.Dashboard_SmallGraph {
}
.Dashboard_SmallGraph_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* padding: 10px; */
  grid-gap: 30px;
  margin-top: 37px;
}
.Dashboard_SmallGraph_grid-item {
  background: #ffffff;
  border-radius: 4px;
  /* max-width: 27vw;
  height: 31vw; */
}
.Dashboard_BMI {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.1vw;
  line-height: 150%;
  color: #1d2334;
  margin-left: 2vw;
  margin-top: 2vw;
}
.Dashboard_SmallGraph_container1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 10px; */
  grid-gap: 30px;
  margin-top: 37px;
}
.Dashboard_SmallGraph_grid-item1 {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 6vw;
  padding: 1vw;
  /* width: 759px;
  height: 390px; */

  /* grid-column: 1/5; */
}
.Dashboard_SmallGraph_grid-item11 {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 6vw;
  /* padding: 1vw; */
  padding-bottom: 6vw;
  /* grid-column: 1/1;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* margin-top: 87vw; */
}

.corporateDashboardBownloadBtn {
  /* position: absolute; */
  margin-left: 85vw;
  margin-top: 18vw;
  background: white;

  border: none;
  outline: none !important;

  cursor: pointer;
}
.PDFDownload {
  position: absolute;
  margin-top: 26vw;
  margin-left: 85vw;
  cursor: pointer;
}

.myServicesDescriptionDownload {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 190px;

  /* border-bottom: 1px dotted black;  */
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.myServicesDescriptionDownload .myServicesDescriptionTextDownload {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 9999;
}

#inputID::placeholder {
  color: #9c9292;
  opacity: 1;
}
.corporateDashboardLoading{
  width: 100%;
}
.imgLoader{
  width: 10%;
  display: flex;
  margin-left: 44%;
  justify-content: center;
  padding-top: 10vw;
  padding-bottom: 10vw;
}
.headingLoading{
  padding-top: 3vw;
  display: flex;
  justify-content: center;
}
/* Show the tooltip text when you mouse over the tooltip container */
.myServicesDescriptionDownload:hover .myServicesDescriptionTextDownload {
  visibility: visible;
}

.dashboardStartDate {
  /* margin-left: 6%; */
  /* margin-top: 2vw; */
  outline: none;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 23.3vw;
  border: none;
  padding-left: 1em;
}

.dashboardStartDateMsg {
  margin-left: 6%;
  /* margin-top: 2vw; */
  outline: none;
  padding-top: 1em;
  padding-bottom: 1em;
  color: red;
  width: 17.3vw;
  border: none;
  /* padding-left: 1em; */
}

.dashboardEndDate {
  width: 23.3vw;
  padding-top: 1em;
  padding-bottom: 1em;
  /* margin-left: 17.1vw; */
  outline: none;
  border: none;
  padding-left: 1em;
}

.dashboardEndDateMsg {
  width: 17.4vw;
  padding-top: 1em;
  color: red;
  padding-bottom: 1em;
  margin-left: 18.8vw;
  outline: none;
  border: none;
  /* padding-left: 1em; */
}
.dashboardSearchFilter1{
  width: 11vw;
  background-color:#52608e;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align:center;
  padding-left: 1vw;
  height: 4.3vw;

}

.dashboardSearchFilter{
  width: 11vw;
  background-color:#52608e;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align:center;
  border: none;
  outline: none !important;
  padding-left: 4vw;
  height: 4.3vw;

}
.dashboardFilter {
  color: #ffffff;
  height: 4.3vw;
  /* margin-left: 16.7vw; */
  border: none;
  outline: none !important;
  /* background: #52608e; */
  border-radius: 4px;
  /* padding-top: 1em; */
  /* padding-bottom: 1em; */
  /* margin-top: 2vw; */
  font-family: "Nunito";
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 150%;
  /* position: absolute; */
  width: 23.3vw;
  cursor: pointer;
}
.button:focus {
  outline: none !important;
}
.filterDashboard {
  height: 0.1vw;
  width: 110%;
  margin-left: -2vw;
}
.filterText {
  margin-left: 2.6vw;
  height: 0.1vw;
}
.DashboardSoonMain {
  /* width: 50%; */
  /* margin-top: 2.18em; */
  height: 37em;
  position: relative;
  margin-bottom: 3em;

}
.DashboardSoonIMage{
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 3vw;
}
.DashboardSoonTextcontainer{
  background: #F0F0F0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 0px 0px 24px 24px;
width: 1236px;
height: 117px;
margin: auto;
display: flex;
justify-content: center;
}
.DashboardSoonTextcolor{
 color: #E68D36; 
}
.DashboardSoonTextcontainerdiv{
  margin-top: 2vw;
  margin-left: 3vw;
  margin-right: 2vw;
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
}
@media screen and (max-width:500px) {
  .ComingSoonMain img{
      width: 100%;
  }
  .ComingSoonMain{
      width: 80%;
  }
  .ComingSoonMain p{
     left: 11%;
     top: 90%;
     font-size: 2em;
}
}
/* @media screen and (width:820px) {
 
  .ComingSoonMain p{
     left: 11%;
     top: 90%;
}
} */
/* */
