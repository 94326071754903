.mainHeadingCorporate {
  width: 79%;
  /* padding-top: 2.06rem; */
  margin: auto auto;
  display: flex;
  align-items: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 1.5em;
  color: #1d2334;
  height: 10vh;
  padding-top: 2em;
  padding-bottom: 1em;
}
.boxTopCorporate {
  width: 80%;
  height: 6.68em;
  margin: auto auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color:chartreuse; */
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
  height: 10vh;
}
.boxTopCorporate .boxTopNameCorporate {
  
  margin-left: 1.8em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1d2334;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.12em;
  line-height: 1.5em;
  width: 60%;
  height: 100%;
}
.boxTopBalanceBoxCorporate {
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11.25em;
  height: 2.5em;
  border: 1px solid #1d2334;
  border-radius: 0.37em;
  margin-right: 2em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5em;
}
.boxBottomCorporate {
  width: 80%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  padding-left: 2.12em;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
  padding-bottom: 5em;
  /* background-color: darkgreen; */
}

.boxBottomNameCorporate {
  padding-top: 1.18em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.5em;
  color: #1d2334;
  margin-bottom: 1em;
}
.boxBottomDescCorporate {
  margin-top: 1.25em;
  width: 80%;
  color: #1d2334;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
}

.templateCardCorporate {
  width: 60%;
  margin-top: 2.6em;
  display: flex;
  background: #ffffff;

  border: 1px solid #1d2334;
  border-radius: 0.75em;
}
.mainTemplateCardCorporate {
  overflow-x: auto;
}
.editCorporate {
  color: #1d2334;
}
.serviceNameCorporate {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 2.6em;
  color: #1d2334;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.12em;
  line-height: 150%;
}
.serviceDetailsCorporate {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1d2334;
}
.attributeCorporate {
  width: 60%;
  margin-top: 1.62em;
  display: flex;
  justify-content: space-between;
}
.boxBottomButtonsCorporate {
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 10vh;
  /* cursor: pointer; */
}
.boxBottomButtonsCorporate button {
  width: 30%;
  height: 70%;
  background-color: #e68d36;
  border: hidden;
  border-radius: 0.75em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 1.5vw;
  color: #ffffff;
  font-family: "Nunito";
  cursor: pointer;
}
.boxBottomButtonCorporates{
  cursor: pointer;
}
