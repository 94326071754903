.editAgent-vals {
  display: flex;
}

.editAgent-val {
  position: absolute;
  margin-left: 30%;
  margin-top: 4%;
  border-radius: 100px;
  width: max-content;
}

.editAgent-detail {
  margin-left: 3%;
}

.editAgent-key {
  margin-top: 5%;
  font-weight: bold;
}

.editAgent-btnCancel {
  background-color: #a53f2b;
}

.agentEditField {
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 12px;
  height: 50px;
}