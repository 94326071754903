.HospitalDashbaordContainerMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 4vw;
  padding-top: 4vw;
  padding-bottom: 9vw;
}

.HospitalDashbaordblockContainer {
  width: 44vw;
  height: 8.5vw;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 3vw;
  display: grid;
  grid-template-columns: 28vw 1fr;
}

.HospitalDashbaordOuterContainer {
  background: #e9e9e9;
  height: 100%;
}
.HospitalDashboardBtn {
  width: 12vw;
  height: 4vw;
  padding-top: 0vw;
  background: #1d2334;
  border-radius: 4px;
  margin-top: 2vw;
  color: white;
  border: none;
  outline: none !important;
}
.HospitalDashboardNameList {
  padding-top: 1vw;
  padding-left: 1vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}
.HospitalDashboardNameList2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  color: #E68D36;
}

.HospitalDashboardFlexdisplay {
  display: flex;
  justify-content: flex-end;
  margin-right: 4vw;
  padding-top: 3vw;
}
.HospitalDashboardStartDateInput {
  border: none !important;
  outline: none !important;
}
.SelectHospitalboxContainer {
  width: 92vw;
  outline: none !important;
  padding-left: 2vw;
  margin-left: 3vw;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
  margin-top: 1vw;
  height: 3vw;
}
