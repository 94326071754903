.DisableModalHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 150%;
  margin: auto;
  color: #000000;
  text-align: center;
}
.DisableModalSubHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  text-align: center;
}
.css-1byq22h {
  width: auto !important;
}

.numberErrorMessageModalValidation {
  color: rgb(163, 45, 24);
  margin-left: auto;
  /* flex-direction: column; */
  /* display: flex; */
  margin-top: 0.875em;
  margin-bottom: -9.4375em;
}
.updateNumbertextarea {
  width: 37vw;
  height: 5vw;
  margin-top: 1vw;
  border: 1px solid #1d2334;
  border-radius: 0.75em;
  color: #1d2334;
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-size: 1em;
  margin-left: auto;
  padding-top: 0.2em;
  /* padding-bottom: 4.06em; */
  padding-left: 1.6em;
  /* border: none !important; */
  outline: none !important;
  padding-right: 1.6em;
  margin-top: 3vw;
}
.DisableModalUploadNumber {
  width: 636px;
  height: 163px;
  left: 437px;
  top: 625px;
  text-align: center;
  background: #ffffff;
  border: 1px dashed #000000;
  margin-left: 11vw;
  margin-top: 1vw;
}
.disableModalSubmitButton {
  display: flex;
  justify-content: center;
}
.disableModalButton {
  width: 25vw;
  height: 2.5em;
  background: #44576a;
  border-radius: 12px;
  color: white;
  border: none;
  outline: none !important;
  margin-bottom: 5%;
  margin-top: 3em;
  cursor: pointer;
}
.uploadSignMain {
  margin-top: 2vw;
}
.fileUploadContainer {
  width: 15vw;
  margin-left: 16vw;
  margin-top: 1vw;
}
input[type="file"] {
  color: grey;
  width: 15vw;
}
::-webkit-file-upload-button {
  color: #838383;
  background: #dfdfdf !important;
  padding: 5px;
  border: none;
  outline: none !important;

  border-radius: 10px;
  /* box-shadow: 1px 0 1px 1px #6b4559; */
  outline: none;
  height: 100%;
  cursor: pointer;
}
.Swal-container {
  z-index: 9999;
}
.css-79ws1d-MuiModal-root {
  z-index: 1 !important;
}


@media screen and (max-width:900px) {
  .css-1byq22h {
    top: 25% !important;
  }
}