.ColumnColourRed {
  color: #a53f2b;
}

.ColumnColorGreen {
  color: #319b4b;
}

.requestDropdownDiscount {
  border: 1.5px solid #52608e;
  border-radius: 6px;
  background-color: white;
  padding: 0.5em 1.6875em 0.5em 1.6875em;
}

.appRHead {
  display: flex;
}

.deleteButton {
  text-align: center;
  background-color: #e43232;
  color: white;
  display: flex;
  justify-content: flex-end;
  margin-left: 1em;
  padding: 0.5em 1em 0em 1em;
  border-radius: 5px;
}
.discountProductArea{
  /* background-color: #319b4b; */
  width: 95%;
  display: flex;
  flex-direction: column;
}
.discountProductRows{
  /* background-color: #a53f2b; */
  width: 70%;
  display: flex;
  margin-top: 2em;
margin-left: 1em;
}

.discountProductRowsLeft{
  width: 50%;
  /* background-color: aqua; */
font-family: Rubik;
font-size: 1em;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 1em;

}
.discountProductRowsRight{
  width: 50%;
  /* background-color: yellow; */
font-family: Rubik;
font-size: 1em;
font-weight: 300;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
.discountProductTilesArea{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.discountProductTiles{
  width: 70%;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1em;
  margin-left: 1em;
  border-radius: 6px;
  margin-right: 1em;
  margin-bottom: 2em;
  padding-right: 1em;
  position: relative;
}
.discountProductTilesRows{
  width: 100%;
  display: flex;
  margin-top:2em;
}
.discountProductArea{
  width: 100%;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
  margin-bottom: 3em;
}
.editDiscountProductButton{
  width: 30%;
   background: #319B4B;
  border: hidden;
  border-radius: 6px;
margin-top: 1em;
color: #fff;
padding: .6em ;
font-family: Rubik;
font-size: 1em;
font-weight: 500;
line-height: 27px;
letter-spacing: 0em;
text-align: center;
margin: 0 auto;
margin-bottom: 2em;
}
.discountPercentageInput{
  width: 100%;
  padding: 0.3em;
  font-family: Rubik;
font-size: 1em;
font-weight: 500;
line-height: 27px;
letter-spacing: 0em;
}
.editProductDiscount{
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
}
.discountDescriptionInput{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 1em;
  padding-right: 1em;
}