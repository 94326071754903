.pharmacyDetailButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4em;
    padding-bottom: 2.5em;
}

.pharmacyDetailButton button {
    background: #319B4B;
    border-radius: 12px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1em;
    line-height: 150%;
    text-align: center;
    color: #F2EFEA;
    padding: 0.7em 5em 0.7em 5em;
    border: hidden;
}

.corporate-value_Pharmacy {
    margin-left: 5.5em;
    /* max-width: 30vw; */
    /* text-transform: capitalize; */
}