.AssignProgramToInsurerMain {
  display: flex;
  flex-direction: column;
  margin-bottom: 5em;
}
.AssignProgramToInsurerUpper {
  margin-left: 5.56em;
  margin-right: 5.56em;
}
.AssignProgramToInsurerUpper :nth-child(1) {
  padding-top: 1em;
  padding-bottom: 1em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  color: #1d2334;
}
.AssignProgramToInsurerUpper :nth-child(2) {
  padding-bottom: 1em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.1em;
  line-height: 150%;
  color: #1d2334;
}
.AssignProgramToInsurerLower {
  margin-left: 5.56em;
  margin-right: 5.56em;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}

.AssignProgramToInsurerLowerChild2 {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2em;
  padding-bottom: 5em;
}
.AssignProgramToInsurerLowerChild2Button {
  border-radius: 12px;
  padding: 0.6em 2.1em 0.68em 2.6em;
  border: hidden;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.1em;
  line-height: 150%;
  color: #f2efea;
  background: #e68d36;
}
.AssignProgramToInsurerCardsChild1 {
  width: 5%;
  background-color: #52608e;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.AssignProgramToInsurerCardsChild2 {
  width: 95%;
  display: flex;
  flex-direction: column;
}
.AssignProgramToInsurerCards {
  display: flex;
  width: 25%;
  /* background-color: chocolate; */
  margin-top: 3em;
  margin-bottom: 2.5em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 2.1em;
  margin-right: 2.1em;
  cursor: pointer;
}
.AssignProgramToInsurerCardsChild2UpperHeading {
  /* background-color: slateblue; */
  width: 75%;
  padding: 1em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  color: #52608e;
}
.AssignProgramToInsurerCardsChild2UpperView {
  width: 25%;
  display: flex;
  justify-content: center;
  padding-top: 1em;
}
.AssignProgramToInsurerCardsChild2Upper {
  display: flex;
}
.AssignProgramToInsurerCardsChild2Lower {
  padding: 1em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  color: #52608e;
}
.AssignProgramToInsurerLowerChild1 {
  display: flex;
  flex-wrap: wrap;
}
/* .AssignProgramToInsurerLowerChild2  {
  background: #ffffff;
  border-radius: 8px;
  padding: 0.6em 0.6em 0.6em 1.1em;
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
} */

.programCountToInsurer{
    background: #ffffff;
    border-radius: 10px;
    padding: 0.6em 0.6em 0.6em 1.1em;
    font-family: "Nunito";
    font-style: italic;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    border: 1px solid gray;
    color: #1d2334;
}

.AssignProgramToInsurerUpperDescriptionArea {
  display: flex;
  flex-direction: column;
  padding-left: 2.1em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  color: #e68d36;
  margin-bottom: 2em;
}
.AssignProgramToInsurerUpperDescriptionArea p {
  margin-top: 1em;
}
