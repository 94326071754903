html{
  background-color: #f9f9f9;
}
.ConsultationRequestHeader {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: #1d2334;
  margin-left: 7.6vw;
  margin-top: 1em;
  margin-bottom: 1em;
}

.ConsultationRequestCard {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-left: 7.6vw;
  margin-right: 19.4vw;
  display: flex;
  justify-content: space-between;
  padding: 2.18vw;
  margin-bottom: 2em;
}

.ConsultationRequestInfo {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}

.ConsultationRequestButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConsultationRequestAcceptButton {
  color: #ffffff;
  background: #319B4B;
  border-radius: 6px;
  width: 8.46vw;
  border: none;
  outline: none !important;

  cursor: pointer;
}

.ConsultationRequestRejectButton {
  margin-left: 1.5vw;
  color: #ffffff;
  background: #A53F2B;
  border-radius: 6px;
  width: 8.46vw;
  border: none;
  outline: none !important;

  cursor: pointer;
}
