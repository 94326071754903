.bookingBottomStat {
  display: flex;
  justify-content: space-around;
  /* background-color: #A53F2B; */
  
}
.bookingBottom{
  /* width: 100%; */
  margin-top: 1em;
  /* background-color: aquamarine; */
}
.bookingBottomStatus {
  background: #e68d36;
  border-radius: 12px;
  padding-left: 5.0625em;
  padding-right: 5.0625em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  /* secondary_color_3 */

  color: #f2efea;
}

.bookingBottomStat :nth-child(1) {
  cursor: pointer;
  margin-top: 2em;
  border: none;
  outline: none !important;

  background-color: #319b4b;
  color: #f2efea;
  padding-left: 4.9375em;
  padding-right: 4.9375em;
  padding-top: 0.75em;
  padding-bottom: 0.5625em;
  border-radius: 12px;
  margin-bottom: 2em;
}
.bookingBottomStat :nth-child(2) {
  cursor: pointer;
  margin-top: 2em;
  border: none;
  outline: none !important;

  background-color: #E68D36;
  color: #f2efea;
  padding-left: 5.9375em;
  padding-right: 5.9375em;
  padding-top: 0.75em;
  padding-bottom: 0.5625em;
  border-radius: 12px;
  margin-bottom: 2em;
}
.bookingBottomStat :nth-child(3) {
  cursor: pointer;
  margin-top: 2em;
  border: none;
  outline: none !important;

  background-color: #A53F2B;
  color: #f2efea;
  padding-left: 5.9375em;
  padding-right: 5.9375em;
  padding-top: 0.75em;
  padding-bottom: 0.5625em;
  border-radius: 12px;
  margin-bottom: 2em;
}
.dates_time2{
  margin-top: -3.4em;
  margin-left:15em ;
}