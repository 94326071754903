.LotStatusPageWrapper {
  padding-left: 7.27vw;
  padding-right: 7.27vw;
}

.LotStatusHeader {
  margin-top: 2.5vh;
  margin-bottom: 1.25vh;
}

.LotStatusHeaderText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 150%;
  color: #1d2334;
}

.LotStatusTable {
  background-color: #ffffff;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}

.LotStatusTableRow1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LotStatusTableRow1Part1 {
  display: flex;
  align-items: center;
  height: 7vw;
}

.LotStatusTableRow2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LotStatusTableRow2Part1 {
  display: flex;
  align-items: center;
  height: 7vw;
  padding-left: 2.25vw;
}

.LotStatusTableRow1Part2{
  padding-right: 2.25vw;
}

.LotStatusTableRow2Part2 {
  display: flex;
  padding-right: 2.25vw;
}

.LotStatusTableRow1Part1Header {
  margin-left: 2.25vw;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw;
  line-height: 150%;
  color: #1d2334;
}

.LotStatusTableRow1Part1NumberCard {
  margin-left: 1.19vw;
  background: #e68d36;
  border-radius: 6px;
  color: #f2efea;
  padding: 0.3vw;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3vw;
  line-height: 150%;
}

.LineSeperator {
  margin-top: 0px;
  margin-bottom: 0px;
}

.LotStatusUpdateButton {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  background: #a53f2b;
  border: none;
  outline: none !important;
  border-radius: 6px;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.25vw;
  padding-bottom: 0.25vw;
  cursor: pointer;
}

.LotStatusDownloadButton {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  background: #319b4b;
  border: none;
  outline: none !important;

  border-radius: 6px;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.25vw;
  padding-bottom: 0.25vw;
  cursor: pointer;
}

.customColumnHeader {
  font-size: 1vw;
  font-weight: 500;
}
.customColumnHeaderWrapper {
  text-align: center;
  padding-top: 5px;
}

.UpdatePaymentInputField {
  background: #ffffff;
  border: 1px solid #52608e;
  border-radius: 6px;
  width: 100%;
  height:3vw;
}


.PaidUpdateStatus{
color: #319b4b;
}
