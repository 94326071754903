.hospital-main {
  margin-left: 6.875em;
  margin-top: 2.06em;
  font-family: "Nuninto", sans-serif;
}

.headerViewName {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
}

.hospital-box {
  margin-right: 8.75rem;
  background-color: white;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}

.counts {
  display: flex;
  justify-content: space-between;
  margin-left: 2.125rem;
  margin-top: 2.5em;
  margin-bottom: 2.625em;
  margin-right: 2.125rem;
}

.divCount {
  display: flex;
}

.docCount {
  background: #e68d36;
  border-radius: 6px;
  padding-left: 1.18em;
  padding-top: 0.18em;
  padding-right: 1.43em;
  padding-bottom: 0.0625em;
  color: white;
}

.serCount {
  margin-left: 2.875em;
  background-color: #a53f2b;
  border-radius: 6px;
  padding-left: 1.18em;
  padding-top: 0.18em;
  padding-right: 1.43em;
  padding-bottom: 0.0625em;
  color: white;
}

.hospitaledit {
  display: flex;
  color: blue;
  cursor: pointer;
}

.hospitalDetails {
  margin-left: 2.125rem;
  margin-top: 2.68em;
  display: flex;
  margin-bottom: 5.125em;
}

.valuess {
  margin-left: 5em;
  /* display: flex; */
  justify-content: flex-start;
  align-items: center;
}
.tagcolor{
  color: blue;
}

.services {
  margin-bottom: 3%;
  margin-left: 2.125rem;
}

.hospitalcard {
  margin-left: 1.525rem;
  padding-top: 1.875em;
  padding-bottom: 1.875em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  background-color: #f6d6b6;
  border: 1px solid #e68d36;
  border-radius: 6px;
}

.hospitalcard-title {
  font-weight: 600;
}

.grid {
  margin-top: 3%;
  margin-bottom: 3%;
  height: 100%;
}

.grid-head {
  display: flex;
  justify-content: space-between;
  margin-left: 2.125em;
  margin-right: 2em;
  align-items: center;
}

.hospitalDoctor {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  /* margin-right: 50em; */
}

.grid-btn {
  display: flex;
  justify-content: flex-end;
  background-color: #319b4b;
  /* margin-left: 53.5em; */
  border: none;
  outline: none !important;
  border-radius: 6px;
  padding-left: 1.18em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1.31em;
  cursor: pointer;
  color: white;
}

.grid-body {
  margin-top: 3%;
  height: 100%;
}

.keys {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* primary_color_1 */

  color: #1d2334;
}

.MuiTablePagination-toolbar {
  display: flex;
  -webkit-box-align: center;
  align-items: baseline;
}

.MuiTablePagination-displayedRows {
  display: none;
}

.MuiTablePagination-actions {
  display: none;
}

.hospital-pagination {
  display: flex;
  margin-left: 50vw;
  margin-right: 5vw;
  align-items: baseline;
  justify-content: space-evenly;
  margin-top: 2vh;
}

.hospital-dropDown {
  padding: 5px;
  border: 12px;
}

.disableChip {
  margin-left: 1vw;
  background-color: red;
  border-radius: 6px;
  padding-left: 1.18em;
  padding-top: 0.18em;
  padding-right: 1.43em;
  padding-bottom: 0.0625em;
  color: white;
}
.disableReason {
  margin-left: 2.125rem;
  padding-top: 1em;
  padding-bottom: 1em;
}
.disableReasonFile {
  display: flex;
  margin-bottom: 3vw;
  margin-left: 2.125rem;
  cursor: pointer;
}