* {
  margin: 0;
  padding: 0;
}
.mainPrivacyPolicy {
  display: flex;
  flex-direction: column;
  /* height: 630vh; */
  background-color: #ffffff;
}
.privacyPolicyTop {
  /* height: 55vh; */
  display: flex;
  justify-content: center;
}
.FooterBackgroundImgArcPrivacyPolicy {
  position: absolute;
}
.FooterBackgroundImagePrivacyPolicy {
  position: absolute;
}
.FooterImagePrivacyPolicy {
  position: relative;
  top: 1.5rem;
}
.privacyPolicyBottom {
  display: flex;
  flex-direction: column;
align-items: center;
  /* justify-content: center; */
  /* height: 575vh; */
}
.contentPrivacyPolicy {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  padding-top: 2em;
  /* position: absolute; */
  z-index: 1;

}
.contentPrivacyPolicyHeading {
  padding-left: 3%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 2.5em;
  line-height: 1.5em;
  color: #2d2424;
}
.contentPrivacyPolicyBottomHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.5em;
  padding-left: 3%;
  color: #292828;
}
.contentPrivacyPolicy p {
  padding-left: 3%;
  padding-right: 3%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  color: #292828;
}
