.ViewPlanDetailMain{
    padding-left: 5.56em;
    padding-right: 5.56em;
}
.ViewPlanDetailHeader{
font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-size: 2em;
line-height: 150%;
color: #1D2334;
padding-top: 1.4em;
padding-bottom: 1.31em;
}
.ViewPlanDetailInfo{
    background: #FFFFFF;
border: 1px solid #EDE8E8;
border-radius: 12px;
display: flex;
flex-direction: column;
padding-left: 2.1em;
padding-top: 1.3em;
padding-right: 6.8em;
margin-bottom: 3.3em;
padding-bottom: 2em;
}
.nthChild1{
    font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-size: 1em;
line-height: 150%;
color: #1D2334;
}
.nthChild2{
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #1D2334;
}
.nthChild3{
    font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-size: 1em;
line-height: 150%;
color: #1D2334;
margin-top: 3em;
}
.ViewPlanDetailPricing{
    display: flex;
    width: 23em;
    background: #FFFFFF;
border: 1px solid #1D2334;
border-radius: 8px;
margin-top: 1.1em;
}
.ViewPlanDetailPricingLeft{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #52608E;
border: 1px solid #1D2334;
border-radius: 8px 0px 0px 8px;
padding-top: 1em;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 0.8em;
line-height: 150%;
color: #F2EFEA;
}
.ViewPlanDetailPricingRight{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #FFFFFF;
border: 1px solid #1D2334;
border-top-right-radius: 8px;
border-bottom-right-radius: 8px;
padding-top: 1em;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 0.8em;
line-height: 150%;
color: #1D2334;
}
.nthChild4{
    font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-size: 1em;
line-height: 150%;
color: #1D2334;
margin-top: 3.3em;
}
.ViewPlanDetailRelation{
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    flex-wrap: wrap;
}
.ViewPlanDetailRelationChild{
    background: #E68D36;
border-radius: 4px;
width: 9em;
display: flex;
justify-content: center;
align-items: center;
padding-top: .4em;
padding-bottom: .4em;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 150%;
color: #FFFFFF;
margin-left: 1.1em;
margin-top: 1.1em;
}
.ViewPlanDetailServices{
    background: #FFFFFF;
border: 1px solid #1D2334;
border-radius: 12px;
width: 38em;
display: flex;
flex-direction: column;
margin-top: 3em;
}
.ViewPlanDetailServicesHeader{
    display: flex;
}
.ViewPlanDetailServicesHeaderHeading{
    width: 40%;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.2em;
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #1D2334;
}
.ViewPlanDetailServicesHeaderInfo{
    width: 60%;
    display: flex;
}
.ViewPlanDetailServicesHeaderInfo div{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 0.7em;
line-height: 150%;
color: #1D2334;
}
.ViewPlanDetailServicesBottom{
    display: flex;
    justify-content: flex-end;margin-bottom: 1em;
}
.pharmacyServicePlanDetail {
    width: 38em;
    border: 1px solid #1D2334;
    border-radius: 0.75em;
    display: flex;
  flex-wrap: wrap;
  margin-top: 3em;


  }
  .pharmacyService1Plan{
    width: 30%;
    font-size: 1.12em;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1D2334;
    padding-top: 1em;
    padding-bottom: 1em;
  }