.button {
  background: #319b4b;
  border-radius: 6px;
  border: none;
  outline: none !important;
  padding-top: 4%;
  padding-bottom: 4%;
  width: 120%;
  color: white;
  cursor: pointer;
}

.btnCreate {
  margin-left: 42%;
}

.card-box {
  display: grid;
  width: 100%;
}

.h4 {
  margin-left: 6%;
  margin-top: 2%;
  font-family: "Nunito", sans-serif;
}

.mainBox {
  margin-top: 1.625em;
  margin-left: 6.875rem;
  margin-right: 5.875em;
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 1.625em;
}

.head {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  /* padding-top: 2%; */
}

.count {
  background-color: #e68d36;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  padding-bottom: 1px;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 2%;
}

.btn {
  text-align: center;
  margin-bottom: 1%;
  background-color: #319b4b;
  color: white;
}

.list {
  /* width: 20%; */
}

.search {
  display: flex;
  margin-bottom: 3%;
  margin-top: 2%;
}

.searchBox {
  margin-left: 10%;
}

.autocomplete {
  width: 20%;
}

.modify {
  display: flex;
}

.box-body {
  display: flex;
  width: 100%;
}

.cards {
  margin-left: 7%;
  border: 1px solid white;
  min-width: 25%;
  max-width: 150%;
  margin-top: 5%;
  cursor: pointer;
  background: #F8FBFE;
box-shadow: 0px 0px 2px 2px rgba(22, 66, 91, 0.05);
border-radius: 6px;
background-color: #e68d36;
}

.card-head {
  margin-top: 5%;
  margin-left: 15%;
  font-family: 'Nunito';
font-style: normal;
font-weight: 700;
font-size: 1em;
line-height: 22px;
color: #1D2334;
}

.card-body {
  margin-left: 9%;
  color: #1d2334;
  font-size: 14px;
}

.card-edit {
  margin-left: 50%;
  cursor: pointer;
}

.card-text {
  margin-top: 2%;
}

.custom-card {
  background-color: white;
  margin-left: 7%;
  border: 1px solid white;
  border-left: 10px solid #319b4b;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  min-width: 25%;
  max-width: 150%;
  margin-top: 2%;
}

.custom-card-head {
  display: flex;
  margin-top: 5%;
  margin-left: 15%;
  width: max-content;
  font-size: 16px;
  color: #319b4b;
}

.card-box {
  margin-bottom: 3.125em;
}

.card-body {
  margin-left: 9%;
  color: #1d2334;
  font-size: 14px;
}

.card-edit {
  margin-left: 50%;
}

.card-text {
  margin-top: 2%;
}

.programsearchBtn {
  border-radius: 6px;
  box-shadow: none;
  height: 40px;
}

.card-head-desc{
  width: 100%;
  padding-left: 1.3em;
  padding-top: 1em;
  font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 150%;
color: #1D2334;
margin-top: 5%;
}
.carding-bottom1New{
  height: 40%;
  display: flex;
  align-items: center;
}
.carding-bottom1Child1New p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 0.8em;
line-height: 150%;
color: #E68D36;
padding-left: 1.5em;
text-transform: capitalize;
}