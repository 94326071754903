.mainOtpBox {
  width: 45%;
  /* height: 35%; */
  /* background: yellowgreen; */
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid rgb(111, 111, 111, 0.5);
}
.upperOtp {
  display: flex;
  /* height: 25%; */
  /* background-color: aqua; */
  align-items: center;
  justify-content: space-between;
}
.expireMessage {
  color: #ff001f;
  margin-top: 0.8rem;
  /* background-color: yellowgreen; */
  margin-left: 15%;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  animation: animate 1s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
.upperOtp p {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 4%;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  padding-top: 1.8rem;
}
.upperOtp a {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 5%;
  padding-top: 1.8rem;
}
.middleOtp {
  width: 100%;
  margin-top: 2.25rem;

  /* height: 35%; */
  /* background-color: red; */
  display: flex;
  align-items: center;
}
.otpHeading {
  height: 100%;
  /* background-color:rgb(255, 136, 0); */
  width: 15%;
  display: flex;
  justify-content: center;
}
.otpHeading p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #44576a;
}
.otpBoxes {
  height: 100%;
  /* background-color:thistle; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 3%; */
}
.otpTimer {
  width: 100%;
  margin-top: 0.87rem;

  /* background-color: darkorchid; */
  display: flex;
}
.otpTimer1 {
  /* background-color: rgb(8, 179, 185); */
  width: 15%;
}
.otpTimer2 {
  /* background-color: forestgreen; */
  width: 50%;
  height: 10%;
  display: flex;
  justify-content: space-between;
}
.otpTimer p {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.8rem;
}
#timeShow {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #ff001f;
}
.resendOtp {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  cursor: pointer;
}
.bottomOtp {
  /* height: 30%; */

  margin-top: 1.68rem;

  /* background-color: brown; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10%;
  margin-bottom: 2.3rem;
}
.bottomOtp button {
  height: 60%;
  width: 45%;
  background: #38466c;
  border-radius: 6px;
  border: hidden;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}


.not-allowed-button{
  cursor: not-allowed;
}

.allowed-button{
  cursor: pointer;
}