.YuvaLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.YuvaLoader img {
  margin-top: 15%;
  height: 15vh;
  width: 7vw;
}

@media screen and (max-width: 500px) {
  .YuvaLoader img {
    margin-top: 15%;
    height: 10vh;
    width: 12vw;
  }
}
