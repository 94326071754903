.style1new {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}
.paymentpreParent1 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  display: flex;
}

.paymentpre1 {
  width: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.3vw;
}
.paymentpreBasic {
  width: 50%;
  flex-wrap: wrap;
  font-size: 1.3vw;
}
.paymentpre5 :nth-child(1) {
  /* margin-top: auto; */
  margin-bottom: 2em;
  font-size: 1.3vw;
}
.paymentpre4 :nth-child(1) {
  margin-top: auto;
  margin-bottom: 1em;
  font-size: 1.3vw;
}
.paymentpre3 :nth-child(1) {
  margin-top: auto;
  margin-bottom: 1em;
  font-size: 1.3vw;
}
.paymentpre2 :nth-child(1) {
  margin-top: auto;
  margin-bottom: 1em;
  font-size: 1.3vw;
}
.paymentpre1 :nth-child(1) {
  margin-top: auto;
  margin-bottom: 1em;
  font-size: 1.3vw;
}
.paymentpreBasic :nth-child(1) {
  margin-top: auto;
  margin-bottom: 1em;
  font-size: 1.3vw;
}
.paymentField {
  font-weight: 700;
  font-size: 1.4vw;
}

.paymentpre2 {
  width: 50%;
  /* height: 100%; */
  /* background-color: rgb(174, 0, 255); */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
}

.paymentpreParent2 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-bottom: 2em;
  /* background-color: #e68d36;
  border: 1px solid black; */
  align-items: center;
}
.paymentpre3 {
  width: 50%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.paymentpre4 {
  width: 50%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.paymentpre5 {
  width: 50%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.horizontalform {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* margin-top: 1em; */
  /* background-color: tomato; */
}
.horizontalform label {
  width: 30%;
  /* background-color: teal; */
  margin-top: 1em;
  margin-bottom: 1em;
}
.inputFieldnew {
  width: 100%;
  padding-left: 1.625em;
  /* width: 40%; */
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  /* margin-bottom: 2.6875em; */
  font-family: "Nunito";
  /* font-style: italic; */
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
}
.inputFieldnew::placeholder {
  color: #1d2334;
}

.modalHeaderTextnew {
  margin-top: 1em;
  display: flex;
  align-items: center;
  text-align: center;
}

.HeaderUPPart1 {
  font-size: 2vw;
  font-weight: 600;
  margin-left: 0.2vw;
}
.HeaderUPPart2 {
  font-size: 2vw;
  font-weight: 500;
  color: black;
  margin-left: 2.5vw;
}
.HeaderUPPart3 {
  font-size: 2vw;
  font-weight: 700;
  color: #a53f2b;
  margin-left: 0.2vw;
}

.modalHeader2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-left: 3vw;
}

.modalButtonPackagenew {
  margin-top: 1em;

  width: 40%;
  /* width: 25vw; */
  height: 2.5em;
  background: #e68d36;
  border-radius: 12px;
  color: white;
  border: none;
  margin-bottom: 1em;
}
.modalButtonPackagenewdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessageModalValidationPayment {
  margin-top: 1em;
  color: rgb(163, 45, 24);
}
