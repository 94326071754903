.insurance-main {
  margin-left: 6.875em;
  margin-top: 2.125em;
  margin-right: 8.75em;
  font-family: "Nuninto", sans-serif;
  margin-bottom: 10%;
}

.insurance-id {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
}

.box {
  background-color: white;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}

.insurance-box-head1 {
  margin-top: 2.5em;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2.5em;
  margin-right: 2em;
  margin-left: 2em;
  /* justify-content: space-between; */
}

.name {
  margin-right: 3%;
}

.agentHeaderName {
  font-size: 20px;
  /* margin-left: 3%; */
  font-weight: bold;
}

.status {
  margin-top: -0.5%;
  background-color: #e68d36;
  margin-right: 3%;
  padding: 5px;
  border-radius: 6px;
}

.edit {
  color: blue;
  margin-right: 3%;
}

.desc {
  margin-top: 2%;
  margin-right: 25%;
  font-weight: 400;
}

.detailss {
  margin-top: 2.0625em;
  display: flex;
  margin-left: 2em;
  font-size: 20px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
}

.insurance-value {
  margin-left: 5.5em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.keyss {
  font-weight: bold;
  margin-bottom: 4.5%;
}

.time {
  width: 200%;
}

.buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 4.375em;
  margin-bottom: 4.0625em;
}

.btnAssign {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px;
  background: #319b4b;
}

.btnDeletes {
  align-items: center;
  padding-left: 9em;
  padding-right: 9em;
  padding-top: 0.625em;
  padding-bottom: 0.6875em;
  border-radius: 12px;
  background: #a53f2b;
  color: white;
  cursor: pointer;
}

.editBtn {
  border: none;
  outline: none !important;

  background-color: white;
  color: blue;
  cursor: pointer;
}

.insurer-details {
  margin-top: 2.0625em;
  display: flex;
  margin-left: 2em;
  font-size: 20px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
}

.btnAssign {
  border: none;
}

.assignProgramInsurer {
  font-size: 20px;
  margin-left: 3%;
}
.assignProgramInsurerHead {
  font-weight: bold;
}
.RelationInsurer{
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
}