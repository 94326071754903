/* .nav-item :hover{
    color: darkgray;
}

.nav-link{
    color: white;
} */

.profile{
    border: 1px solid #C0C0C0;
    border-radius: 10px;
    max-width: 21%;
    padding-left: 2%;
    padding-right: 1%;
    padding-top: 6px;
    
}

.picture{
    display: flex;
    padding: 10px;
   
}

.dropdown{
    display: flex;
}

.navbar-brand{
    margin-left: 1em;
    color: white;
}

.navbar-nav{
    display: flex;
    justify-content:space-around;
    align-items: center;
}

.navbarLogo{
    height: 27px;
}

.dropdown-item{
    color: gray;
    cursor: pointer;
}
.dropdown-item1{
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    /* color: #212529; */
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    color: gray;
    cursor: pointer;
}
.dropdown-item1:hover{
    color: antiquewhite;
    background-color: darkgray;
}
.dropdown-item:hover{
    color: antiquewhite;
    background-color: darkgray;
}