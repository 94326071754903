.addCreateCardNumberModal {
    width: 79%;
    min-height: 70vh;
    background-color: white;
    top: 24%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #efe7e7;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    margin-bottom: 3vw;
}

.addCreateCardNumberChildLeft {
  display: flex;
  width: 20%;
  align-items: center;
  color: var(--primary-color-11, #52608e);
  font-family: Rubik;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.addCreateCardNumberChildRight select {
  width: 90%;
  color: var(--primary-color-11, #52608e);
  font-family: Rubik;
  font-size: 1em;
  font-style: normal;
  border-radius: 12px;
  font-weight: 500;
  line-height: 150%;
  padding: 0.6em 0.6em 0.6em 1em;
}

.addCreateCardNumberChildRight {
  display: flex;
  width: 55%;
  align-items: center;
  color: var(--primary-color-11, #52608e);
  font-family: Rubik;
  font-size: 1em;
  border-radius: 12px;

  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 0.6em 0.6em 0.6em 1em;
}

.addCreateCardNumberChildRight input {
  width: 90%;
  color: var(--primary-color-11, #52608e);
  font-family: Rubik;
  font-size: 1em;
  font-style: normal;
  border: 1px solid #52608e;

  border-radius: 12px;
  outline: none;
  font-weight: 500;
  line-height: 150%;
  padding: 0.6em 0.6em 0.6em 1em;
}

.addCreateCardNumberButton {
    color: var(--secondary-color-3, #F2EFEA);
    font-family: Nunito;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 6px;
    background: #52608E;
    border: hidden;
    padding: 0.6em 1em;
    margin-top: 1.5em;
}