.servicename {
  margin-top: 2.06em;
  margin-left: 6.875rem;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
}

.servicemainBox {
  margin-top: 1.0625em;
  margin-left: 6.875em;
  margin-right: 8.75em;
  border: 1px solid #ede8e8;
  background-color: white;
  border-radius: 12px;
}

.servicehead {
  margin-top: 2.5em;
  margin-left: 2em;
  margin-right: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
}

.serviceLeftHead {
  display: flex;
  justify-content: space-between;
}

.servicelist {
  /* margin-left: 2.125em;
    margin-top: 2.5em; */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

.servicecount {
  background-color: #e68d36;
  padding-left: 1.1875em;
  padding-right: 1.1875em;
  padding-top: 0.1875em;
  padding-bottom: 0.0625em;
  border-radius: 0.375em;
}

.service-addbtn {
  /* margin-left: 39.75em;
    margin-top: 2.5em; */
  border: none;
  outline: none !important;

  padding: 6px;
  /* margin-right: 2em; */
  text-align: center;
  /* margin-bottom: 1%; */
  background-color: #319b4b;
  color: white;
}

.modify {
  display: flex;
}

.box-body {
  display: flex;
  width: 100%;
}

.cardse1 {
  margin-top: 5%;
  background-color: white;
  margin-left: 7%;
  border: 1px solid white;
  border-left: 10px solid #52608e;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 25%;
  display: flex;
  flex-direction: column;
  min-height: 25vh;
}

.card-head1 {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  height: 60%;
  width: 100%;
}
.card-head1Child1 {
  width: 70%;
  padding-left: 1em;
  padding-top: 1em;
  padding-bottom: 2em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  color: #52608e;
  text-transform: capitalize;
}
.card-head1Child2 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 1em;
}
.carding-bottom1 {
  height: 40%;
  display: flex;
  align-items: center;
}
.carding-bottom1Child1 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1em;
}
.carding-bottom1Child1 p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 150%;
  color: #e68d36;
}
.carding-bottom1Child2 p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 150%;
  color: #319b4b;
}
.carding-bottom1Child2 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
}
.service-card-edit {
  cursor: pointer;
}

.card-text {
  margin-top: 2%;
}

.service-custom-card {
  margin-top: 5%;
  background-color: white;
  margin-left: 7%;
  border: 1px solid white;
  border-left: 10px solid #319b4b;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  min-width: 25%;
  max-width: 150%;
}

.custom-card-head {
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  margin-left: 15%;
  width: max-content;
  font-size: 16px;
  color: #319b4b;
}

.carderer-body {
  margin-left: 15%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 150%;
  margin-right: 5%;
  margin-top: 20%;
  margin-bottom: 3%;
  color: #e68d36;
}
.carderer-bodynew {
  display: flex;
  justify-content: space-between;
}
.carderer-bodynew :nth-child(2) {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 150%;
  color: #319b4b;
}
.card-text {
  margin-top: 2%;
}

.all {
  width: 4.68rem;
  height: 3rem;
  margin-left: 3.12rem;
  margin-top: 0.8rem;
  margin-bottom: 540;
  background: #52608e;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #f2efea;
  border: 1px solid #52608e;
  border-radius: 6px;
}

.subscribe {
  width: 8.47rem;
  height: 3rem;
  margin-left: 2.25rem;
  margin-top: 1.18rem;
  background: #ffffff;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #e68d36;

  border: 1px solid #e68d36;
  border-radius: 6px;
}
.custom {
  width: 6.81rem;
  height: 3rem;
  margin-left: 3.25rem;
  margin-top: 1.18rem;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #319b4b;
  background: #ffffff;
  border: 1px solid #319b4b;
  border-radius: 6px;
}
.searcherrBar {
  margin-left: 37.5em;
  /* gap: 6.5625em; */
}
.searcherrSection {
  display: flex;
  /* gap: 6.5625em; */
}
.left__Head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.searchersSection {
  display: flex;
  gap: 1.5625em;
}
