.appointmentmain {
  margin-left: 6.875em;
  margin-top: 1.9375em;
  margin-right: 8.75em;
  font-family: "Nuninto", sans-serif;
}

.appId {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
}

.appointmentbox-head {
  margin-top: 2.5em;
  margin-left: 2.125em;
  margin-bottom: 2.6875em;
  display: flex;
}

.viewBox-head {
  /* margin-top: 2.5em; */
  margin-left: 2.125em;
  /* margin-bottom: 2.6875em; */
  display: flex;
  font-weight: 800;
  font-size: 22px;
}

.name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.statuses {
  /* padding-left: 1.1875em; */
  padding-right: 0.9375em;
  /* padding-top: 0.1875em; */
  padding-bottom: 0.0625em;
  margin-left: 0.125em;
  /* color: #f2efea; */
}

.appointment-box {
  background-color: white;
  border: 1px solid #ede8e8;
  border-radius: 12px;
}

.appointment-box-head {
  margin-top: 3%;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2%;
}

.appointment-name {
  margin-right: 3%;
}

.appointment-box-body {
  margin-left: 2.125em;
}

.app-desc {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.desc {
  margin-top: 1.75em;
  margin-right: 34.125em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.appointment-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.appoi {
  display: flex;
  justify-content: space-between;
  margin-top: 2.75em;
}

.appointment-keys {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}

.appointment-value {
  margin-left: 5.5em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.inputFieldOtp {
  padding-left: 25px;
  width: 25vw;
  height: 40px;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 15px;
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.Confirmed_otpForm {
  margin-top: 5.56em;
  margin-right: 2.5em;
  border: 2px solid #646161;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding-left: 2.1875em;
  padding-right: 1.625em;
  width: 45%;
}

.Confirmed_otphead {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.Confirmed_otpfoot {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-top: 2vw;
  margin-bottom: 2em;
}

.Confirmed_startBtn {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  background: #52608e;
  border-radius: 12px;
  width: 20rem;
  height: 3rem;
  left: 63.125rem;
  top: 46rem;
  color: white;
  cursor: pointer;
  margin-bottom: 1.5625rem;
}

.appointment-buttons {
  width: 90%;
  display: flex;
  /* background-color: #319b4b; */
  /* flex-wrap: wrap; */
  justify-content: space-around;
  margin-top: 3.375em;
  margin-bottom: 5%;
  /* margin-left: 6.625em; */
  /* margin-right: 5.75em; */
  position: relative;
  right: 5em;
}

.appointment-btnAssign {
  padding-left: 5.9375em;
  padding-right: 5.9375em;
  padding-top: 0.75em;
  padding-bottom: 0.5625em;
  border-radius: 12px;
  background: #319b4b;
  border: none;
  outline: none !important;

  cursor: pointer;
  color: white;
}

.appointment-btnCancel {
  padding-left: 5.9375em;
  padding-right: 5.9375em;
  padding-top: 0.75em;
  padding-bottom: 0.5625em;
  padding-bottom: 10px;
  border-radius: 12px;
  background: #e68d36;
  color: white;
  border: none;
  outline: none !important;
  cursor: pointer;
}

.appointment-btnDelete {
  padding-left: 5.9375em;
  padding-right: 5.9375em;
  padding-top: 0.75em;
  padding-bottom: 0.5625em;
  padding-bottom: 10px;
  border-radius: 12px;
  background: #a53f2b;
  cursor: pointer;
  color: white;
  border: none;
  outline: none !important;
}

.otpInput {
  width: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.timeSlot {
  display: flex;
  margin-bottom: 1em;
}
.AppointmentTimeSlot {
  /* margin-top: 10.3vw; */
}

.startBtn {
  background: #52608e;
  border-radius: 12px;
  width: 320px;
  height: 48px;
  left: 1010px;
  top: 736px;
  color: white;
}

::-webkit-file-upload-button {
  color: white;
  background: #206a5d;
  padding: 5px;
  border: none;
  outline: none !important;

  border-radius: 10px;
  /* box-shadow: 1px 0 1px 1px #6b4559; */
  outline: none;
  height: 100%;
  cursor: pointer;
}

.appointment-fileName {
  display: flex;
  margin-left: 8.1em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.errorSize {
  color: red;
}
.finalScreenDetailsUploadReportChild22 {
}
.orfield {
  margin-left: 33vw;
  margin-top: 2vh;
  font-size: 1.5em;
}
.NewCreateButtonAppointment {
  margin-left: 6vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  border-radius: 12px;
  background: var(--primary-color-1, #1d2334);
  color: var(--secondary-color-3, #F2EFEA);
text-align: center;
/* Subtitle Tag 18px_Bold */
font-family: Rubik;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 150%;
}
.prescriptionAppointment{
  display: flex;
  margin-top:3vh;
}
.DownloadPDF1{
  margin-left:14.5vh;
  color:blue;
  cursor: pointer;
  margin-bottom: 3vh;
}
.appointment-keysUp{
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 3vh;
}