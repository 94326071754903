.ViewCouponMainChild1 {
  margin-left: 6.8em;
  margin-right: 4.2em;
  display: flex;
  align-items: center;
  height: 20vh;
}
.ViewCouponMainChild1 img {
  width: 1.2em;
  height: 1.2em;
  margin-top: auto;
  margin-bottom: auto;
}
.ViewCouponMainChild1 p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  color: #1d2334;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  position: relative;
  bottom: 0.1em;
}
.ViewCouponMainChild2 {
  /* background-color: aqua;
    border: 1px solid black; */
  margin-left: 6.8em;
  margin-right: 4.2em;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-bottom: 1px solid #ffffff;

  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.ViewCouponMainChild3 {
  /* background-color: aqua;
    border: 1px solid black; */
  margin-left: 6.8em;
  margin-right: 4.2em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 8em;
  background: #ffffff;
  border: 1px solid #ede8e8;
  border-top: 1px solid #ffffff;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
.ViewCouponMainChild3 :nth-child(1) {
  background: #e68d36;
  border-radius: 12px;
  padding: 0.6em 9em 0.6em 9em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.1em;
  line-height: 150%;
  text-align: center;
  color: #f2efea;
  margin-top: 1em;
  margin-bottom: 3em;
  border: hidden;
}
.ViewCouponMainChild3 :nth-child(2) {
  background: #a53f2b;
  border-radius: 12px;
  padding: 0.6em 9em 0.6em 9em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.1em;
  line-height: 150%;
  text-align: center;
  color: #f2efea;
  margin-top: 1em;
  margin-bottom: 3em;
  border: hidden;
}
.infoDiv {
  display: flex;
  /* background-color: #a52b8b; */
  margin-top: 2em;
  align-items: center;
}
.infoDivField {
  width: 25%;
  padding-left: 2.12em;
  /* background-color: yellowgreen;
    border: 1px solid black; */
  height: 100%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}
.infoDivValue {
  width: 75%;
  /* background-color: yellowgreen;
    border: 1px solid black; */
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}
.infoDivValueDiv {
  display: flex;
}
.lastInfoDiv {
  margin-bottom: 6.5em;
}
.couponCode2 {
  width: 30%;
  height: 100%;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  position: relative;
  right: 1em;
}
.pencil {
  cursor: pointer;
  width: 1em;
  height: 1em;
}
.dateField {
  display: flex;
}
.enddate {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.EditButton {
  width: 35%;
  background: green;
  border-radius: 12px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.1em;
  line-height: 150%;
  text-align: center;
  color: #f2efea;
  border: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .infoDivValueDivChild{
    margin-left: 1em;
} */

.infoDivValueDivChild {
  width: 25%;
}
.infoDivValueDivChild li {
  list-style-type: square;
}
