.footerMain {
  display: flex;
  /* width: 100vw; */
  justify-content: space-around;
  margin-top: 3%;

  flex-wrap: wrap;
  padding-top: 2%;
}

.footerLogo {
  width: 200px;
}

.footerPart1 {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerPart2 {
  display: flex;
  flex-direction: column;
}

.footerPart2Top {
  display: flex;
  justify-content: space-evenly;
  gap: 15px;
}

.footerPart2Bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerPart3 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-right: 3%;
}
