.AddPlanWrapper {
  padding-left: 7.27vw;
  padding-right: 7.27vw;
  margin-bottom: 6em;
}

.AddPlanHeader {
  margin-top: 2.5vh;
  margin-bottom: 1.25vh;
}

.AddPlanHeaderText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 150%;
  color: #1d2334;
}

.AddPlanMain {
  display: flex;
}

.AddPlanMainLeft {
  width: 70%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 65em;

}

.AddPlanMainRight {
  width: 30%;
  background: linear-gradient(12.37deg, #52608e 0%, #18203a 100%);

  border-radius: 12px;

}

.AddPlanMainRightHeader {
  color: #ffffff;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 150%;
  padding: 1.4vw;
}

.AddPlanMainRightList {
  display: flex;
  padding: 1.4vw;
  flex-direction: column;
}

.AddPlanMainRightListIndividual {
  display: flex;
  margin-bottom: 3vh;
  /* align-items: center; */
}

.AddPlanMainRightListButton {
  background: none;
  border: none;
  cursor: pointer;
}

.AddPlanMainRightListButtonPlus {
  font-size: 1.2vw;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
}
.AddPlanMainRightListButtonPlus:hover {
  color: rgb(96, 192, 52);
}

.AddPlanMainRightListName {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 150%;
  color: #ffffff;
  margin-left: 1vw;
}

.AddPlanSeperator {
  margin-top: 0px;
  margin-bottom: 1.8vh;
  border-top: 2px solid #f2efea;
}

.AddPlanSubscription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3vh;
  height: 5vh;
  width: 79%;
  margin-left: 3.81em;
  margin-right: 6.06em;
}

.AddPlanAmountField {
  width: 10vw;
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 12px;
  padding-left: 1em;
  height: 5vh;
}

.AddPlanInputField {
  cursor: pointer;
}

.AddPlanAmountDisabled {
  width: 10vw;
  background: #d3d3d3;
  border: 1px solid #1d2334;
  border-radius: 12px;
  padding-left: 1em;
  height: 5vh;
}

.AddPlanAmountDisabled::placeholder {
  color: #1d2334;
  font-family: "Nunito";
  font-size: 0.9vw;
}
.AddPlanAmountDisabled:input {
  padding-left: 1em;
}

.AddPlanAmountField::placeholder {
  color: #18203a;
  font-family: "Nunito";
  font-size: 0.9vw;
}
.AddPlanAmountField:input {
  padding-left: 1em;
}

.AddPlanSubscriptionName {
  font-family: "Nunito";
  font-weight: 400;
  font-size: 1vw;
  color: #1d2334;
  text-align: center;
  align-items: center;
}

.AddPlanMainLeftInputField {
  width: 79%;
  margin-bottom: 2.5vw;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 12px;
  height: 3em;
  padding-left: 1em;
  margin-left: 3.81em;
  margin-right: 6.06em;
  margin-top: 2vw;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 1.62em;
}

.AddPlanMainLeftInputField::placeholder {
  color: #18203a;
}

.AddPlanMainLeftInputFieldChild {
    justify-content: center;
    background: #ffffff;
    border: 1px solid #1d2334;
    border-radius: 12px;
    height: 3em;
    padding: 1em 1em 1em 1em;
    width: 79%;
    margin-left: 3.81em;
  margin-right: 6.06em;
  margin-top: .6em;
  margin-bottom: 2.5em;
  }
  
  .AddPlanMainLeftInputFieldChild::placeholder {
    color: #18203a;
  }

.relationsComponent {
  width: 79%;
  margin-left: 3.81em;
  margin-right: 6.06em;
  margin-top: 3vw;
  margin-bottom: 1vw;
}

.PlanDescriptionTextArea {
  border: 1px solid #1d2334;
  border-radius: 12px;
  height: 5em;

  margin-bottom: 3vw;
  width: 79%;
  margin-left: 3.81em;
  margin-right: 6.06em;
  padding-top: 0.75em;
  padding-bottom: 4.06em;
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.PlanDescriptionTextArea::placeholder {
    color: #18203a;
  }


  .FeaturedPackCheckDiv{
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* align-items:center; */
  }

  .FeaturedPackCheckText{
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 1.1vw;
    color: #ffffff;
    margin-left: 1.3vw;
  }
  .attributeCardBox{
    width: 79%;
    margin-left: 3.81em;
  margin-right: 6.06em;
  }
  
  
  
  .addPlanSubmit{
    width: 90%;
    margin-top: 5em;
    text-align: center;
    background: #e68d36;
    border-radius: 0.75em;
    height: 3em;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    border: hidden;
  }
  .otherservices{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 1em;
  }
  
  .inputFieldAddParameterFirstPlans1{
    margin-top: 1.25em;
  width: 100%;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 0.625em;
  padding: 0.6em 1em 0.6em 1.7em;
  }
  .inputFieldAddParameterFirstPlans2{
    margin-top: 1.25em;
  width: 100%;
  height: 2.5em;
  border: 1px solid #52608e;
  border-radius: 12px;
  background-color: white;
  color: #1d2334;
  margin-bottom: 0.625em;
  padding: 1em 1em 2.75em 1.7em;

  }
  .hospitalType{
    background: #FFFFFF;
    border: 1px solid #2D354E;
    border-radius: 12px;
    width: 79%;
    margin-left: 3.81em;
  margin-right: 6.06em;
  margin-bottom: 1em;
  }
  .hospitalTypeHeader{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #2D354E;
  }
  .hospitalTypeHeader p{
    padding-top: 1.5em;
    padding-left: 2em;
  }
  .hospitalTypeFooter{
    display: flex;
  }
  .hospitalTypeFooterChild1{
    width: 33.33%;
    padding: 1em;
    padding-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
  }
  .hospitalTypeFooterChild1 label{
    margin-top: auto;
    margin-bottom: auto;
  }