.corporatemain {
  margin-left: 6.875em;
  margin-top: 2.06em;
  font-family: "Nuninto", sans-serif;
  margin-bottom: 10%;
}

.corporate {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
}

.corporate-head {
  display: flex;
  align-items: center;
}

.corporate-box {
  background-color: white;
  border: 1px solid #ede8e8;
  border-radius: 12px;
  margin-right: 8.75em;
}



.corporate-name {
  margin-left: 2.125em;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  /* text-transform: capitalize; */
  display: flex;
  text-align: center;
  justify-content: space-between;

}

.corporate-details {
  display: flex;
  margin-top: 2.68em;
  margin-left: 2.125em;
}

.corporate-keys {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}

.corporate-value {
  margin-left: 5.5em;
  max-width: 30vw;
  /* text-transform: capitalize; */
}

.corporate-buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 7%;
  margin-bottom: 5%;
}

.corporate-btnAssigned {
  padding-left: 7.06em;
  padding-right: 7.06em;
  padding-top: 0.625em;
  padding-bottom: 0.625em;
  border-radius: 12px;
  background: #e68d36;
  border: 1px solid #e68d36;
  color: white;
  outline: none !important;
  cursor: pointer;
}

.corporate-btnAssign:hover {
  cursor: pointer;
}
.ProgramAssignedMainHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #1d2334;
  margin-left: 34px;
  padding-top: 1vw;
}
.ProgramAssignedMainConatiner {
  display: flex;
  justify-content: space-between;
 }
 .ProgramAssignedMainButton{
  background-color: #319b4b;
  margin-right:3vw;
  color: white;
  outline: none !important;
  border: none;
  padding: 1vw;
  cursor: pointer;
}
.viewProgramDisplay {
  display: flex;
  justify-content: space-around;
  margin-top: 1vw;
}
.AssignedviewProgramDisplay {
  width: 30%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #1d2334;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.AssignedviewProgramCorporate {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}
.ViewProgramtemplateCardCorporate {
  width: 60%;
  margin-top: 2.6em;
  display: flex;
  background: #ffffff;
  border: 1px solid #1d2334;
  border-radius: 0.75em;
}

.AssignedviewProgramCorporateChild1 {
  width: 40%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  color: #1d2334;
}
.AssignedviewProgramCorporateChild2 {
  width: 60%;
  display: flex;
}
.AssignedviewProgramCorporateChild1Part1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  color: #1d2334;
}
.AssignedviewProgramCorporateChild0 {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
}
.AssignedviewProgramCorporateField {
  display: flex;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  color: #1d2334;
  width: 60%;
  align-self: flex-end;
}
.AssignedviewProgramCorporateFieldChild1 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
